export enum AnalyticsEventsEnum {
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  OPEN_MOBILE = 'open_mobile',
  COURSE_CREATION_START = 'course_creation_start',
  COURSE_CREATION_COMPLETE = 'course_creation_complete',
  COURSE_PURCHASE = 'course_purchase',
  COURSE_COMPLETION = 'course_completion',
  BID = 'bid',
  WIZ_START = 'wiz_start',
  WIZ_COMPLETE = 'wiz_complete',
  PVP_WIZ_START = 'pvp_wiz_start',
  PVP_WIZ_COMPLETE = 'pvp_wiz-complete',
  EARN_VIRTUAL_CURRENCY = 'earn_virtual_currency',
  SPEND_VIRTUAL_CURRENCY = 'spend_virtual_currency',
  REWARD_RECEIVED = 'reward_received',
  PAGE_VIEW = 'PageView',
  SHARE = 'share',
  SEARCH = 'search',
  LEVEL_START = 'level_start',
  LEVEL_END = 'level_end',
}
