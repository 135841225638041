import { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/modal/close.svg';
import { ReactComponent as ThunderIcon } from '../../assets/icons/wiz/thunder.svg';
import { BottomSheet } from '../../horizon-components/bottom-sheet/ui';
import { MButton } from '../../horizon-components/MButton';
import { Modal } from '../../horizon-components/Modal';
import { useWizContext } from '../../providers/WizProvider/WizProvider';
import { QuizTestModal } from '../../widgets/QuizTestModal';

export const WizEnergy = () => {
  const [isEnergyModalOpen, setIsEnergyModalOpen] = useState(false);
  const { livesCount, fetchForceCompleteQuiz, currentTest } = useWizContext();

  return (
    <>
      {!currentTest ? (
        <>
          <MButton
            variant="secondary"
            color="primary"
            size="xs"
            className="!rounded-full"
            onClick={() => setIsEnergyModalOpen(true)}
          >
            {livesCount} <ThunderIcon />
          </MButton>
        </>
      ) : (
        <MButton
          variant="secondary"
          color="primary"
          size="xs"
          className="!h-9 !w-9 !rounded-full !p-2"
          onClick={fetchForceCompleteQuiz}
        >
          <CloseIcon />
        </MButton>
      )}
      {window.innerWidth > 768 ? (
        <Modal
          isOpen={isEnergyModalOpen}
          onClose={() => setIsEnergyModalOpen(false)}
          showClose={false}
        >
          <QuizTestModal onClose={() => setIsEnergyModalOpen(false)} />
        </Modal>
      ) : (
        <BottomSheet
          open={isEnergyModalOpen}
          onDismiss={() => setIsEnergyModalOpen(false)}
        >
          <QuizTestModal onClose={() => setIsEnergyModalOpen(false)} />
        </BottomSheet>
      )}
    </>
  );
};
