import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Quiz } from '../../../modules/Wiz/Quiz';
import { WizProvider } from '../../../providers';
import { MainRoutes, WizRoutes } from './types/routes';
import { Navigate } from '../../../horizon-components/Navigate';
import { NotFoundPage } from '../../../components/NotFoundPage';
import { WizMain } from '../../../modules/Wiz/wiz-main';
import { QuizMainTest } from '../../../modules/Wiz/wiz-main/ui/quiz-main-test';

export const WizRouter: React.FC = () => {
  return (
    <div className="AddBottom mx-auto w-full grow sm-max:p-2">
      <Routes>
        <Route
          path="/"
          element={<Navigate to={MainRoutes.wiz + WizRoutes.quiz} replace />}
        />
        <Route
          path={WizRoutes.quiz}
          element={
            <WizProvider>
              <Quiz />
            </WizProvider>
          }
        />
        <Route
          path={WizRoutes.quizzes}
          element={
            <WizProvider>
              <WizMain />
            </WizProvider>
          }
        />
        <Route
          path={WizRoutes.quizzes + '/match/:id'}
          element={
            <WizProvider>
              <QuizMainTest />
            </WizProvider>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};
