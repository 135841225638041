import classNames from 'classnames';
import './ProgressBar.scss';

interface ProgressBarProps {
  rating?: number;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  isCritical?: boolean;
}

export const ProgressBar = ({
  rating = 0,
  size = 'sm',
  isCritical = false,
}: ProgressBarProps) => {
  return (
    <div
      className={classNames('ProgressBar', size, {
        '!bg-primary-red-100': isCritical,
      })}
    >
      <div
        className={classNames('ProgressBar__progress', {
          '!bg-primary-red-500': isCritical,
        })}
        style={{ width: `${rating}%` }}
      ></div>
    </div>
  );
};
