import instance, { AUTH_API_URL } from './instance';
import { ResponsePagination } from '../models/Response';
import {
  StatusTaskEnum,
  TAdBack,
  TaskType,
  TCondition,
  TTaskType,
  TUserProgress,
} from '../models/Tasks';

export function getPrivateTasks(data: {
  execution_frequency?: string;
  task_type?: TaskType;
  status_task?: StatusTaskEnum;
  ordering?: string;
  page?: number;
}): Promise<ResponsePagination<TTaskType>> {
  const { execution_frequency, ordering, page, status_task, task_type } = data;
  return instance()
    .get(`${AUTH_API_URL}/private/tasks/`, {
      params: { execution_frequency, task_type, status_task, ordering, page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPublicTasksV2(
  page?: number
): Promise<ResponsePagination<TTaskType>> {
  return instance()
    .get(`${AUTH_API_URL}/public/v2/tasks/`, { params: { page } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateUserProgressV2(): Promise<Record<number, TUserProgress>> {
  return instance()
    .get(`${AUTH_API_URL}/private/v2/user/progress/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPrivateStartTask(
  task_id: number
): Promise<{ error?: string; status?: string }> {
  return instance()
    .post(`${AUTH_API_URL}/private/start-task/${task_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPrivateClaimedTaskReward(
  task_id: number
): Promise<{ error?: string; status?: string }> {
  return instance()
    .post(`${AUTH_API_URL}/private/claimed/task-reward/${task_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateRetrieveTaskCondition(
  task_id: number
): Promise<TCondition> {
  return instance()
    .get(`${AUTH_API_URL}/private/retrieve/task-condition/${task_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPrivateAds(
  exchangeAdId: string,
  img: string,
  title: string
): Promise<TAdBack> {
  return instance()
    .post(`${AUTH_API_URL}/private/ads/`, {
      exchange_ad_id: exchangeAdId,
      img,
      title: title,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export async function getPrivateAds(): Promise<TAdBack[]> {
  return instance()
    .get(`${AUTH_API_URL}/private/ads/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPrivateClaimReward(
  exchangeAdId: string
): Promise<{ error?: string; status?: string }> {
  return instance()
    .post(`${AUTH_API_URL}/private/exchange-ad/claim-reward/`, {
      exchange_ad_id: exchangeAdId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export async function sendBannerClick(
  exchangeWidgetId: string,
  exchangeAdId: string,
  formData: any
) {
  try {
    const bannerClickUrl = `https://app.barzha.com/api/exchange/click/${exchangeWidgetId}/${exchangeAdId}`;
    const response = await fetch(bannerClickUrl, {
      method: 'POST',
      body: formData,
    });

    if (response.status !== 200) {
      throw new Error('No response from the ad URL.');
    }

    const data = await response.json();

    if (data.status === 'success') {
      // window.location.href = data.data;
      // console.log('data', data.data);
      return data.data;
    }

    return false;
  } catch (error) {
    console.error('Fetch error:', error);
    return false;
  }
}
