import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ReactComponent as NewLogo } from '../../assets/icons/new-logo.svg';
import { TitleBack } from '../../components/TitleBack';
import { useNavigate } from '../../hooks/useNavigate';
import { HButton } from '../../horizon-components/Button/HButton';
import { Link } from '../../horizon-components/Link';
import { MTooltip } from '../../horizon-components/MTooltip/MTooltip';
import { useUserContext } from '../../providers';
import getPlatform from '../../utils/getPlatform';
import { PlatformsEnum } from '../../entities/Platforms/platforms';
import NavbarMenu from './NavbarMenu';
import NavbarMenuAnonymous from './NavbarMenuAnonymous';
import {
  AuthRoutes,
  MAIN_PAGE_ROUTE,
  MainRoutes,
  WizRoutes,
} from './Routes/types/routes';
import { ReactComponent as StartIcon } from '../../assets/icons/start/play.svg';
import { saveCurrentUrl } from '../../api/sessions';
import { AppDispatch } from '../../stores';
import { useDispatch } from 'react-redux';
import useCurrentSubjectData from '../../hooks/useCurrentSubjectData';
import { setIsOpenLevelModal } from '../../stores/subject';

const Navbar = ({ isMapPage = false }: { isMapPage?: boolean }) => {
  const { user } = useUserContext();
  const { pathname } = useLocation();
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { lang } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const subjectData = useCurrentSubjectData();

  const goTo = (url: string) => () => {
    if (url === MainRoutes.start && isMapPage && !subjectData?.isLevelCompleted) {
      if (!user) {
        saveCurrentUrl(window.location.pathname);
        navigate(MainRoutes.auth + AuthRoutes.signin);
        return;
      }
      dispatch(setIsOpenLevelModal(true));
    } else if (url === MainRoutes.tasks && !user) {
      saveCurrentUrl(window.location.pathname);
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    } else {
      navigate(url);
    }
  };

  const navbarButtons = () => (
    <div className="flex gap-x-4 lg-max:hidden">
      <HButton
        id={'home'}
        color={pathname === `/${lang}/` ? 'dark' : 'light'}
        className={'!rounded-[16px] !p-[12px] !text-[16px] !font-[600]'}
        onClick={goTo('/')}
      >
        {t('menu.home')}
        <MTooltip anchorSelect={'#home'} text={t('menu.home')} />
      </HButton>
      <HButton
        id={'edu-btn'}
        color={pathname.indexOf('edu') > -1 ? 'dark' : 'light'}
        className={'!rounded-[16px] !p-[12px] !text-[16px] !font-[600]'}
        onClick={goTo(MainRoutes.edu)}
      >
        EDU
        <MTooltip anchorSelect={'#edu-btn'} text={t('menu.educationalPlatform')} />
      </HButton>
      <HButton
        id={'wiz-btn'}
        color={pathname.indexOf('wiz') > -1 ? 'dark' : 'light'}
        className={'!rounded-[16px] !p-[12px] !text-[16px] !font-[600]'}
        onClick={goTo(MainRoutes.wiz + WizRoutes.quizzes)}
      >
        WIZ
        <MTooltip anchorSelect={'#wiz-btn'} text={t('menu.quiz')} />
      </HButton>
      <HButton
        id={'bid-btn'}
        color={pathname.indexOf('bid') > -1 ? 'dark' : 'light'}
        className={'!rounded-[16px] !p-[12px] !text-[16px] !font-[600]'}
        onClick={goTo(MainRoutes.bid)}
      >
        BID
        <MTooltip anchorSelect={'#bid-btn'} text={t('menu.bid')} />
      </HButton>
      <HButton
        id={'tasks-btn'}
        color={pathname.indexOf('tasks') > -1 ? 'dark' : 'light'}
        className={'!rounded-[16px] !p-[12px] !text-[16px] !font-[600]'}
        onClick={goTo(MainRoutes.tasks)}
      >
        {t('menu.Tasks')}
        <MTooltip anchorSelect={'#tasks-btn'} text={t('menu.tasks')} />
      </HButton>
      <HButton
        id={'start-btn'}
        color={pathname.indexOf('start') > -1 ? 'dark' : 'light'}
        className={
          '!rounded-[16px] !bg-[#4318FF] p-[12px] !pl-[24px] pr-[14px] !text-[16px] !font-[600]'
        }
        onClick={goTo(MainRoutes.start)}
      >
        <div className="flex items-center gap-[8px]">
          <p className="text-white">Play</p>
          <StartIcon width="20px" height="20px" fill="white" />
        </div>
        <MTooltip anchorSelect={'#start-btn'} text={t('menu.start')} />
      </HButton>
    </div>
  );
  if (getPlatform() === PlatformsEnum.TELEGRAM) {
    document.documentElement.style.setProperty(
      '--safe-area-top-height',
      'calc(var(--tg-safe-area-inset-top) + var(--tg-content-safe-area-inset-top) - 0.5rem)'
    );
    document.documentElement.style.setProperty('--safe-area-top-bc', '#212d3b');
    //   setTimeout(()=> {
    //     if (document.documentElement.style.cssText.includes("--tg-safe-area-inset-bottom: 0px;")) {
    //     // failsafe color to prevent white on white
    //     console.log('set failsafe color')
    //     document.documentElement.style.setProperty("--safe-area-top-bc", "#151e27");
    //   }
    // }, 100)
  }
  // document.documentElement.style.setProperty("--safe-area-top-height", "calc(var(--tg-safe-area-inset-top) + var(--tg-content-safe-area-inset-top) - 0.5rem)");
  // let platformstyle = getPlatform() === PlatformsEnum.TELEGRAM ? 'SafeAreaTgTop' : 'SafeAreaAppTop'
  // if (document.documentElement.style.cssText.includes("--tg-safe-area-inset-bottom: 0px;")) {
  //   platformstyle = 'SafeAreaTgTopFailsafe';
  // }
  return (
    <>
      <div
        className={`sticky top-0 z-50 flex flex-col ${isMapPage ? 'bg-[#2d396b] lg:bg-white' : 'backdrop-blur-xl'}`}
      >
        <div id="top-safe-area" className={'SafeAreaTop'}></div>
        <nav
          className={`duration-175 linear flex w-full flex-row flex-wrap items-center justify-between p-2 transition-all md:!px-6 md:!py-3 
          ${isMapPage ? 'fixed z-50 mx-2 rounded-[24px] bg-white' : ''}`}
          style={
            isMapPage
              ? {
                  top: 'calc(var(--safe-area-top-height) + 0.25rem)',
                  width: 'calc(100% - 16px)',
                }
              : {}
          }
        >
          <div className={'flex flex-row items-center gap-4 lg:gap-[117px]'}>
            <Link to={MAIN_PAGE_ROUTE} className={'block lg-max:hidden'}>
              <NewLogo className="h-4 w-[60px] lg:h-5 lg:w-[74px]" />
            </Link>
            <div className={'hidden items-center lg-max:flex'}>
              <Link to={MAIN_PAGE_ROUTE}>
                <NewLogo className="h-4 w-[60px] lg:h-5 lg:w-[74px]" />
              </Link>
            </div>
            {navbarButtons()}
          </div>

          {user?.guid ? (
            <NavbarMenu isMapPage={isMapPage} />
          ) : (
            <NavbarMenuAnonymous isMapPage={isMapPage} />
          )}
          <TitleBack className="lg:!hidden" />
        </nav>
        {/*<Training />*/}
      </div>
    </>
  );
};

export default Navbar;
