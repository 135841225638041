import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WizRulesModal } from '../../../../widgets/wiz-rules-modal';
import { CoinIcon } from '../../../../components/CoinIcon';
import { NoAvatar } from '../../../../widgets/NoAvatar';
import { useRandomEmoji } from '../../hooks/useRandomEmoji';
import { useAppDispatch, useAppSelector } from '../../../../stores/hooks';
import { getPvPQuizInfoAsync, setGameState } from '../../../../stores/wiz';
import { useRewardContext } from '../../../../providers/RewardProvider';
import { useUserContext } from '../../../../providers';
import { WizFooterButtons } from './wiz-modal-buttons';
import { getUserSomeDataStorage } from '../../../../api';
import { Loader } from '../../../../components/Loader';

interface WizMainEndProps {
  className?: string;
  onClose?: () => void;
}

export const WizMainEnd = ({ className, onClose }: WizMainEndProps) => {
  const { t } = useTranslation(['translation']);

  const { user } = useUserContext();
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const { getRandomEmoji } = useRandomEmoji();
  const [randomEmoji, setRandomEmoji] = useState(getRandomEmoji('wait'));
  const { rewards } = useRewardContext();
  const dispatch = useAppDispatch();
  const { pvpQuizInfo, pvpQuizInfoLoading, pvpQuizInfoError, startPvpQuiz } =
    useAppSelector((state) => state.wiz);
  const [isImageLoading, setIsImageLoading] = useState(true);

  useEffect(() => {
    const matchViewStatus = getUserSomeDataStorage('matchViewStatus', user?.guid);

    const checkResult = () => {
      dispatch(getPvPQuizInfoAsync(matchViewStatus.matchId)).then((action) => {
        if (
          action.payload &&
          typeof action.payload === 'object' &&
          'status' in action.payload &&
          'winner' in action.payload &&
          action.payload.status !== null &&
          action.payload.status !== undefined
        ) {
          if (
            action.payload.status === 'completed' &&
            action.payload.winner === null
          ) {
            dispatch(setGameState('draw'));
          } else if (
            action.payload.status === 'completed' &&
            action.payload.winner
          ) {
            if (action.payload.winner === user?.guid) {
              dispatch(setGameState('win'));
            } else {
              dispatch(setGameState('lost'));
            }
          }
        }
      });
    };
    checkResult();
    const interval = setInterval(checkResult, 3000);
    return () => clearInterval(interval);
  }, [dispatch, startPvpQuiz?.id, user?.guid]);

  const handleRules = () => {
    setIsRulesOpen(!isRulesOpen);
  };

  const price = Number(
    rewards?.find((reward) => reward.tokenomic_type === pvpQuizInfo?.chosen_bet)
      ?.value
  )?.toFixed(0);

  return (
    <div
      className={classNames(
        'absolute bottom-0 left-0 right-0 top-0 z-[60] flex h-screen w-screen flex-col bg-primary-purple-blue-400 p-2',
        className
      )}
    >
      <div
        className="flex h-full flex-col gap-4"
        style={{ marginTop: 'calc(var(--safe-area-top-height))' }}
      >
        <div className="flex min-h-52 flex-col justify-end gap-4 text-center text-white">
          <h4 className="text-xl font-bold">
            {t('wiz.We_are_waiting_for_the_game_to_end')}
          </h4>
          {randomEmoji ? (
            <img
              src={randomEmoji}
              className="mx-auto h-[100px] w-[100px]"
              alt={t('wiz.We_are_waiting_for_the_game_to_end')}
              onLoad={() => setIsImageLoading(false)}
              onError={() => setIsImageLoading(false)}
            />
          ) : isImageLoading ? (
            <Loader className="mx-auto hidden h-[100px] w-[100px] tall:block" />
          ) : null}
        </div>
        <div className="flex justify-between rounded-2xl border border-primary-purple-blue-200 bg-primary-purple-blue-100 p-4 text-sm font-semibold text-primary-purple-blue-700">
          <span>{t('wiz.Bids')}</span>
          <span>
            {price} <CoinIcon className="h-4 fill-primary-purple-blue-700" />
          </span>
        </div>
        <div className="flex items-center justify-around rounded-2xl border border-primary-purple-blue-200 bg-primary-purple-blue-100 p-4 text-sm font-semibold text-primary-purple-blue-700">
          <div className="flex w-[120px] flex-col gap-1 text-center">
            <NoAvatar
              src={pvpQuizInfo?.participants[0].profile_image || undefined}
              alt={''}
              className="relative m-auto !h-[34px] !w-[34px] rounded-full"
            />
            <h5 className="max-w-full truncate text-sm font-bold text-secondary-grey-900">
              {pvpQuizInfo?.participants[0].username}
            </h5>
          </div>
          <span className="text-base font-bold text-primary-purple-blue-500">
            vs
          </span>
          <div className="flex w-[120px] flex-col gap-1 text-center">
            <NoAvatar
              src={pvpQuizInfo?.participants[1].profile_image || undefined}
              alt={''}
              className="relative m-auto !h-[34px] !w-[34px] rounded-full"
            />
            <h5 className="max-w-full truncate text-sm font-bold text-secondary-grey-900">
              {pvpQuizInfo?.participants[1].username}
            </h5>
          </div>
        </div>
      </div>
      <WizFooterButtons onRules={handleRules} onClose={onClose} />
      <WizRulesModal isOpen={isRulesOpen} onClose={handleRules} />
    </div>
  );
};
