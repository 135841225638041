import classNames from 'classnames';
import { TQuestionsData } from '../../../../api/wiz/wiz';
import { LoaderMini } from '../../../../components/LoaderMini';
import { useMemo } from 'react';

interface IAnswer {
  quiz_question_id: number;
  question_answer_id: number;
}

interface QuizAnswersProps {
  currentQuestion: TQuestionsData;
  correctAnswer: number | null;
  answers: IAnswer[];
  onAnswerSelect: (answerId: number) => void;
  isSubmittingAnswer?: boolean;
  selectedAnswerId?: number | null;
}

export const QuizAnswers = ({
  currentQuestion,
  correctAnswer,
  answers,
  onAnswerSelect,
  isSubmittingAnswer,
  selectedAnswerId,
}: QuizAnswersProps) => {
  const shuffledAnswers = useMemo(() => {
    return [...currentQuestion.answers].sort(() => Math.random() - 0.5);
  }, [currentQuestion]);

  return (
    <div className="grid grid-cols-1 flex-wrap content-start items-start gap-2 self-stretch text-center md:grid-cols-2">
      {shuffledAnswers.map((answer) => (
        <div
          key={answer.id}
          onClick={() => !isSubmittingAnswer && onAnswerSelect(answer.id)}
          className={classNames(
            'relative flex h-[3rem] min-h-[3rem] w-full flex-col items-center justify-center  gap-1 rounded-[20px] border-2 p-3 text-base font-semibold md:min-h-[100px] md:p-2 md:text-lg md:font-bold',
            {
              'border-primary-purple-blue-100 bg-primary-purple-blue-100 text-primary-purple-blue-500':
                !correctAnswer ||
                (correctAnswer &&
                  !answers.some((a) => a.question_answer_id === answer.id) &&
                  correctAnswer !== answer.id),
              'border-primary-green-500 bg-white text-primary-green-700':
                correctAnswer === answer.id,
              'border-primary-red-500 bg-white text-primary-red-500':
                correctAnswer &&
                answers.some((a) => a.question_answer_id === answer.id) &&
                correctAnswer !== answer.id,
              'pointer-events-none': correctAnswer,
            },
            correctAnswer
              ? ''
              : 'hover:border-primary-purple-blue-300 hover:bg-primary-purple-blue-300 hover:text-primary-purple-blue-100'
          )}
        >
          <span>{answer.text}</span>
          {isSubmittingAnswer && selectedAnswerId === answer.id && (
            <LoaderMini className="absolute right-1 top-1 -mb-1 !h-5 !w-5 lg:!h-7 lg:!w-7" />
          )}
        </div>
      ))}
    </div>
  );
};
