import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WizRulesModal } from '../../../../widgets/wiz-rules-modal';
import { CoinIcon } from '../../../../components/CoinIcon';
import { NoAvatar } from '../../../../widgets/NoAvatar';
import { useAppDispatch, useAppSelector } from '../../../../stores/hooks';
import { getPvPQuizInfoAsync } from '../../../../stores/wiz';
import { useRewardContext } from '../../../../providers/RewardProvider';
import { setUserSomeDataStorage } from '../../../../api';
import { useUserContext } from '../../../../providers';
import { MatchViewStatus } from '../types';
import { useNavigate } from '../../../../hooks/useNavigate';
import {
  MainRoutes,
  WizRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { Loader } from '../../../../components/Loader';
import { MButton } from '../../../../horizon-components/MButton';

interface WizMainStartProps {
  className?: string;
  onClose?: () => void;
}

export const WizMainStart = ({ className, onClose }: WizMainStartProps) => {
  const { t } = useTranslation(['translation']);
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const { rewards } = useRewardContext();
  const { user, updateWallet } = useUserContext();
  const navigate = useNavigate();
  const handleRules = () => {
    setIsRulesOpen(!isRulesOpen);
  };
  const dispatch = useAppDispatch();

  const { pvpQuizInfo, pvpQuizInfoLoading, pvpQuizInfoError, startPvpQuiz } =
    useAppSelector((state) => state.wiz);

  useEffect(() => {
    updateWallet();
  }, []);

  useEffect(() => {
    if (startPvpQuiz?.id) {
      dispatch(getPvPQuizInfoAsync(startPvpQuiz.id));
    }
    if (pvpQuizInfoError) {
      console.error('Error fetching PvP Quiz Info:', pvpQuizInfoError);
    }
  }, [dispatch, startPvpQuiz?.id]);

  useEffect(() => {
    if (startPvpQuiz?.id) {
      const matchViewStatus: MatchViewStatus = {
        matchId: startPvpQuiz.id.toString(),
        startedAt: pvpQuizInfo?.started_at,
        hasSubmittedAnswers: false,
        isViewedResult: false,
        answers: [],
      };

      setUserSomeDataStorage('matchViewStatus', matchViewStatus, user?.guid);
    }
  }, [pvpQuizInfo, user?.guid]);

  const price = Number(
    rewards?.find((reward) => reward.tokenomic_type === pvpQuizInfo?.chosen_bet)
      ?.value
  )?.toFixed(0);

  const [countdown, setCountdown] = useState(5);
  const [shouldNavigate, setShouldNavigate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          setShouldNavigate(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [countdown]);

  useEffect(() => {
    if (shouldNavigate && startPvpQuiz?.id) {
      navigate(`${MainRoutes.wiz}${WizRoutes.quizzes}/match/${startPvpQuiz.id}`);
    }
  }, [shouldNavigate, navigate, startPvpQuiz?.id]);

  return (
    <div
      className={classNames(
        'absolute bottom-0 left-0 right-0 top-0 z-[60] flex h-screen w-screen flex-col bg-primary-purple-blue-400 p-2',
        className
      )}
    >
      <div className="flex h-full flex-col gap-4">
        <div className="flex min-h-52 flex-col justify-end gap-4 text-center text-white">
          <h4 className="text-xl font-bold">{t('wiz.The_game_will_start_in')}</h4>
          <h1 className="text-7xl font-bold leading-[80px] text-white">
            {countdown}
          </h1>
        </div>
        <div className="flex justify-between rounded-2xl border border-primary-purple-blue-200 bg-primary-purple-blue-100 p-4 text-sm font-semibold text-primary-purple-blue-700">
          <span>{t('wiz.Bids')}</span>
          <span>
            {price} <CoinIcon className="h-4 fill-primary-purple-blue-700" />
          </span>
        </div>
        <div className="flex items-center justify-around rounded-2xl border border-primary-purple-blue-200 bg-primary-purple-blue-100 p-4 text-sm font-semibold text-primary-purple-blue-700">
          {pvpQuizInfoLoading ? (
            <Loader />
          ) : (
            <>
              <div className="flex w-[120px] flex-col gap-1 text-center">
                <NoAvatar
                  src={pvpQuizInfo?.participants[0].profile_image || undefined}
                  alt={''}
                  className="relative m-auto !h-[34px] !w-[34px] rounded-full"
                />
                <h5 className="max-w-full truncate text-sm font-bold text-secondary-grey-900">
                  {pvpQuizInfo?.participants[0].username || undefined}
                </h5>
              </div>
            </>
          )}
          <span className="text-base font-bold text-primary-purple-blue-500">
            vs
          </span>
          {pvpQuizInfoLoading ? (
            <Loader />
          ) : (
            <>
              <div className="flex w-[120px] flex-col gap-1 text-center">
                <NoAvatar
                  src={pvpQuizInfo?.participants[1].profile_image || undefined}
                  alt={''}
                  className="relative m-auto !h-[34px] !w-[34px] rounded-full"
                />
                <h5 className="max-w-full truncate text-sm font-bold text-secondary-grey-900">
                  {pvpQuizInfo?.participants[1].username || undefined}
                </h5>
              </div>
            </>
          )}
        </div>
      </div>
      {/* <WizFooterButtons onRules={handleRules} onClose={onClose} /> */}
      <div
        className="flex flex-col gap-2"
        style={{ marginBottom: 'calc(var(--safe-area-bottom-height))' }}
      >
        <MButton
          className="!h-12"
          onClick={handleRules}
          variant="highlighted"
          color="primary"
          size="xs"
        >
          {t('wiz.Rules')}
        </MButton>
      </div>
      <WizRulesModal isOpen={isRulesOpen} onClose={handleRules} />
    </div>
  );
};
