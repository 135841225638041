import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getStorage } from '../../../api';
import { removeRedirectPath } from '../../../api/sessions';
import { submitBulkAnswers, TCA } from '../../../api/wiz/wiz';
import { Loader } from '../../../components/Loader';
import SEO from '../../../components/SEO/SEO';
import { useUserContext } from '../../../providers';
import { useWizContext } from '../../../providers/WizProvider/WizProvider';
import getErrorMessages from '../../../utils/getErrorMessages';
import { QuizCategories } from '../../../widgets/QuizCategories';
import { QuizHeader } from '../../../widgets/QuizHeader';
import { QuizTest } from '../../../widgets/QuizTest';
import './style.scss';
import { useNavigate } from '../../../hooks/useNavigate';
import {
  MainRoutes,
  WizRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

interface QuizProps {
  className?: string;
}

export const Quiz = ({ className }: QuizProps) => {
  const { t } = useTranslation(['translation']);
  const { user, updateWallet } = useUserContext();
  const [isStarted, setIsStarted] = useState(false);
  const [isTest, setIsTest] = useState<null | boolean>(null);
  const { currentTest, isCurrentAuthTestLoading, handleCloseTest } = useWizContext();
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const claimRewardTestId = urlSearchParams.get('claimReward');

  useEffect(() => {
    if (isTest === false && currentTest === null) {
      navigate(`${MainRoutes.wiz}${WizRoutes.quizzes}`);
    }
  }, [isTest, currentTest]);

  useEffect(() => {
    const answers = getStorage('answers') as unknown as TCA[];

    if (claimRewardTestId && answers?.length) {
      submitBulkAnswers(answers)
        .then(() => {
          updateWallet();
          toast(t('wiz.The_reward_for_the_test_has_been_successfully_received'), {
            type: 'success',
          });
          removeRedirectPath();
        })
        .catch((error) => {
          toast(getErrorMessages(error?.response?.data), {
            type: 'error',
          });
        })
        .finally(handleCloseTest);
    }
  }, [claimRewardTestId, t]);

  useEffect(() => {
    setIsTest(!!currentTest);
    setIsStarted(!!currentTest);
  }, [currentTest]);

  const hndlStartMore = () => {
    setIsStarted(!isStarted);
    setIsTest(!isTest);
  };

  return (
    <div className={classNames('Quiz', className, { '!max-w-[1064px]': isTest })}>
      <SEO title={t('pages.wiz.meta.title')} desc={t('pages.wiz.meta.desc')} />

      <QuizHeader />
      {isCurrentAuthTestLoading ? (
        <Loader />
      ) : !isTest ? (
        <QuizCategories />
      ) : (
        <QuizTest
          onStart={() => (!user?.guid ? setIsTest(!isTest) : hndlStartMore())}
        />
      )}
    </div>
  );
};
