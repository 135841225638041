import { useTranslation } from 'react-i18next';
import { MButton } from '../../../../horizon-components/MButton';
import { QUESTION_COUNT } from '../constants';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/modal/close.svg';

interface QuizHeaderProps {
  currentQuestionIndex: number;
  onCloseClick: () => void;
}

export const QuizHeader = ({
  currentQuestionIndex,
  onCloseClick,
}: QuizHeaderProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <div className="mb-2 flex w-full items-center justify-center gap-2.5 rounded-3xl bg-white p-2 shadow-md shadow-shadow-700 lg:p-3">
      <div className="flex flex-[1_0_0] items-center gap-2.5 text-sm font-semibold text-primary-purple-blue-300">
        <span className="ml-1.5 text-base font-semibold text-purple-600 lg:text-xl">
          {t('wiz.Question')} {currentQuestionIndex + 1}/{QUESTION_COUNT}
        </span>
      </div>
      <div className="flex flex-[1_0_0] items-center justify-end gap-2.5">
        <MButton
          variant="secondary"
          color="primary"
          size="xs"
          className="!h-9 !w-9 !rounded-full !p-2"
          onClick={onCloseClick}
        >
          <CloseIcon />
        </MButton>
      </div>
    </div>
  );
};
