import { WizMainPvP } from './wiz-main-pvp';
import { WizMainClassic } from './wiz-main-classic';
import { WizMainWait } from './wiz-main-wait';
import { WizMainEnd } from './wiz-main-end';
import { WizMainStart } from './wiz-main-start';
import { RootState } from '../../../../stores';
import { useDispatch, useSelector } from 'react-redux';
import { setGameState } from '../../../../stores/wiz';
import { WizMainResult } from './wiz-main-result';
import { useEffect } from 'react';

type GameState = 'start' | 'wait' | 'win' | 'draw' | 'lost' | 'end' | null;

const GAME_COMPONENTS = {
  start: WizMainStart,
  wait: WizMainWait,
  win: WizMainResult,
  draw: WizMainResult,
  lost: WizMainResult,
  result: WizMainResult,
  end: WizMainEnd,
};

export const WizMain = () => {
  // const { user } = useUserContext();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const matchViewStatus = getUserSomeDataStorage('matchViewStatus', user?.guid);
  //   if (matchViewStatus) {
  //     const startTime = new Date(matchViewStatus.startedAt).getTime();
  //     const currentTime = new Date().getTime();
  //     const elapsedSeconds = (currentTime - startTime) / 1000;
  //     if (matchViewStatus.hasSubmittedAnswers === false && elapsedSeconds <= 305) {
  //       navigate(
  //         `${MainRoutes.wiz}${WizRoutes.quizzes}/match/${matchViewStatus.matchId}`
  //       );
  //     }
  //   }
  // }, [user]);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      event.preventDefault();
      dispatch(setGameState('null'));
    };

    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [dispatch]);

  const state = useSelector((state: RootState) => state.wiz.gameState);

  const handleStateChange = (newState: GameState) => () => {
    dispatch(setGameState(newState));
  };

  if (state && state in GAME_COMPONENTS) {
    const Component = GAME_COMPONENTS[state as keyof typeof GAME_COMPONENTS];
    return <Component onClose={handleStateChange(null)} />;
  }

  return (
    <div className="flex flex-col gap-2">
      <WizMainClassic />
      <WizMainPvP />
    </div>
  );
};
