import { ProgressBar } from '../../../../horizon-components/ProgressBar';
import { useAppDispatch, useAppSelector } from '../../../../stores/hooks';
import { useEffect, useState } from 'react';
import { setSubmitAnswer, submitPvpQuiz, TCA } from '../../../../api/wiz/wiz';
import { getPvPQuizInfoAsync, setGameState } from '../../../../stores/wiz';
import {
  getUserSomeDataStorage,
  removeUserSomeDataStorage,
  setUserSomeDataStorage,
} from '../../../../api';
import { useUserContext } from '../../../../providers';
import { QUESTION_COUNT, TIME, TOTAL_PERCENT } from '../constants';
import { IAnswer, MatchViewStatus } from '../types';
import { useNavigate } from '../../../../hooks/useNavigate';
import {
  MainRoutes,
  WizRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { ExitConfirmationModal } from '../../../../widgets/ExitConfirmationModal/ExitConfirmationModal';
import { Loader } from '../../../../components/Loader';
import { useExitConfirmation } from '../../hooks/useExitConfirmation';
import { QuizHeader } from './QuizHeader';
import { QuestionContent } from './QuestionContent';
import { QuizAnswers } from './QuizAnswers';
import useFetch from '../../../../hooks/useFetch';

export const QuizMainTest = () => {
  const { user } = useUserContext();
  const { pvpQuizInfo, pvpQuizInfoLoading } = useAppSelector((state) => state.wiz);

  const matchViewStatus: MatchViewStatus = getUserSomeDataStorage(
    'matchViewStatus',
    user?.guid
  );
  const [encryptedAnswers, setEncryptedAnswers] = useState<TCA[]>(
    matchViewStatus?.encryptedAnswers || []
  );
  const questionsEncrypt = pvpQuizInfo?.questions_encrypt;
  const startedAt = pvpQuizInfo?.started_at;
  const dispatch = useAppDispatch();
  const [elapsedSeconds, setElapsedSeconds] = useState<number>(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [answers, setAnswers] = useState<IAnswer[]>(matchViewStatus?.answers || []);
  const [timeLeft, setTimeLeft] = useState<number>(TIME);
  const navigate = useNavigate();
  const [correctAnswer, setCorrectAnswer] = useState<number | null>(null);
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);
  const [selectedAnswerId, setSelectedAnswerId] = useState<number | null>(null);
  const { fetchData: fetchSubmitPvpQuiz } = useFetch(submitPvpQuiz);

  const { isModalOpen, handleCloseClick, handleModalClose, handleConfirmExit } =
    useExitConfirmation({
      encryptedAnswers,
      pvpQuizInfo,
      currentQuestionIndex,
      user,
    });

  useEffect(() => {
    if (matchViewStatus === undefined) {
      navigate(`${MainRoutes.wiz}${WizRoutes.quizzes}`);
    }
  });

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      event.preventDefault();
      setGameState(null);
      handleCloseClick();
    };

    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  useEffect(() => {
    if (!pvpQuizInfo) {
      dispatch(getPvPQuizInfoAsync(parseInt(matchViewStatus.matchId)));
    }
  }, [pvpQuizInfo, matchViewStatus]);

  useEffect(() => {
    if (elapsedSeconds >= 300) {
      fetchSubmitPvpQuiz(encryptedAnswers, Number(matchViewStatus.matchId))
        .then(() => {
          setUserSomeDataStorage(
            'matchViewStatus',
            { ...matchViewStatus, hasSubmittedAnswers: true },
            user?.guid
          );
          dispatch(setGameState('end'));
          navigate(`${MainRoutes.wiz}${WizRoutes.quizzes}`);
        })
        .catch((error) => {
          setUserSomeDataStorage(
            'matchViewStatus',
            { ...matchViewStatus, hasSubmittedAnswers: true },
            user?.guid
          );
          console.error('Error submitting quiz answers:', error);
          dispatch(setGameState('end'));
        });
    }
  }, [elapsedSeconds]);

  useEffect(() => {
    if (!startedAt) return;
    const answeredQuestions = matchViewStatus?.answeredQuestions ?? -1;
    const initialSeconds = Math.max(
      0,
      Math.floor(
        (new Date().getTime() - new Date(startedAt).getTime() - 5000) / 1000
      ),
      (answeredQuestions + 1) * TIME
    );
    setElapsedSeconds(initialSeconds);

    const result =
      Math.floor(initialSeconds / TIME) <= answeredQuestions
        ? answeredQuestions + 1
        : Math.floor(initialSeconds / TIME);

    const questionIndex = Math.min(result, QUESTION_COUNT - 1);

    setCurrentQuestionIndex(questionIndex);
    setTimeLeft(TIME - (initialSeconds % TIME));

    const interval = setInterval(() => {
      setElapsedSeconds((prev) => {
        const newElapsedSeconds = prev + 1;

        const newResult =
          Math.floor(newElapsedSeconds / TIME) <= answeredQuestions
            ? answeredQuestions + 1
            : Math.floor(newElapsedSeconds / TIME);

        const newQuestionIndex = Math.min(newResult, QUESTION_COUNT - 1);

        setCurrentQuestionIndex(newQuestionIndex);
        setTimeLeft(TIME - (newElapsedSeconds % TIME));
        return newElapsedSeconds;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [startedAt]);

  const questionsData = pvpQuizInfo?.questions_data;

  const currentQuestion = questionsData?.[currentQuestionIndex];

  const handleAnswerSelect = async (answerId: number) => {
    setElapsedSeconds((prev) => {
      if (TIME - (prev % TIME) <= 2) {
        return prev - 2;
      }
      return prev;
    });

    if (!currentQuestion?.id || !questionsEncrypt) return;

    setIsSubmittingAnswer(true);
    setSelectedAnswerId(answerId);

    const newAnswer = {
      quiz_question_id: currentQuestion.id,
      question_answer_id: answerId,
    };
    const updatedAnswers = [...answers, newAnswer];
    setAnswers(updatedAnswers);

    try {
      const response = await setSubmitAnswer(
        currentQuestion.id,
        answerId,
        questionsEncrypt
      );
      setEncryptedAnswers((prev) => [...prev, response.ea]);

      const matchViewStatus: MatchViewStatus = {
        matchId: pvpQuizInfo.id.toString(),
        startedAt: pvpQuizInfo?.started_at,
        isViewedResult: false,
        hasSubmittedAnswers: false,
        answers: updatedAnswers,
        encryptedAnswers: [...encryptedAnswers, response.ea],
        answeredQuestions: currentQuestionIndex,
      };
      setUserSomeDataStorage('matchViewStatus', matchViewStatus, user?.guid);

      setCorrectAnswer(response.correct);
      setTimeout(() => {
        const updatedEncryptedAnswers = [...encryptedAnswers, response.ea];
        if (questionsData && currentQuestionIndex === questionsData.length - 1) {
          fetchSubmitPvpQuiz(
            updatedEncryptedAnswers,
            Number(matchViewStatus.matchId)
          )
            .then(() => {
              setUserSomeDataStorage(
                'matchViewStatus',
                { ...matchViewStatus, hasSubmittedAnswers: true },
                user?.guid
              );
              navigate(`${MainRoutes.wiz}${WizRoutes.quizzes}`);
              dispatch(setGameState('end'));
            })
            .catch((error) => {
              navigate(`${MainRoutes.wiz}${WizRoutes.quizzes}`);
              console.error('Error submitting quiz answers:', error);
              dispatch(setGameState('end'));
            });
        } else {
          handleNextQuestion();
        }
        setCorrectAnswer(null);
        setIsSubmittingAnswer(false);
        setSelectedAnswerId(null);
      }, 1000);
    } catch (error) {
      console.error('Error submitting answer:', error);
      handleNextQuestion();
    } finally {
      setIsSubmittingAnswer(false);
      setSelectedAnswerId(null);
    }
  };

  const handleNextQuestion = () => {
    if (questionsData && currentQuestionIndex < questionsData.length - 1) {
      const nextIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex((prev) => prev + 1);
      setTimeLeft(TIME);
      setElapsedSeconds(nextIndex * TIME);
    }
  };

  if (pvpQuizInfoLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader />
      </div>
    );
  }
  if (!currentQuestion) return null;

  return (
    <div>
      <QuizHeader
        currentQuestionIndex={currentQuestionIndex}
        onCloseClick={handleCloseClick}
      />
      <div className="flex flex-col items-center justify-center gap-4 self-stretch bg-primary-white md:gap-[60px] md:rounded-[30px] md:p-10 md:shadow-md md:shadow-shadow-700">
        {/* <div className="text-3xl">{elapsedSeconds}</div> */}
        <QuestionContent currentQuestion={currentQuestion} />
        <QuizAnswers
          currentQuestion={currentQuestion}
          correctAnswer={correctAnswer}
          answers={answers}
          onAnswerSelect={handleAnswerSelect}
          isSubmittingAnswer={isSubmittingAnswer}
          selectedAnswerId={selectedAnswerId}
        />
        <div className="flex flex-col justify-end gap-4 self-stretch md:flex-row md:items-center md:gap-6">
          <div className="flex flex-[1_0_0] items-center gap-3 rounded-2xl bg-secondary-grey-100 px-3">
            <div
              className={`flex min-h-[38px] min-w-[38px] items-center justify-center text-2xl font-bold text-primary-purple-blue-400 ${timeLeft < 6 ? 'text-primary-red-500' : ''}`}
            >
              {timeLeft}
            </div>
            <ProgressBar
              size="md"
              rating={(timeLeft / TIME) * TOTAL_PERCENT}
              isCritical={timeLeft < 6}
            />
          </div>
        </div>
      </div>
      <ExitConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleConfirmExit}
      />
    </div>
  );
};
