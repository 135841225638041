import { configureStore } from '@reduxjs/toolkit';
import auctionReducer from './auction';
import startReducer from './subject';
import wizReducer from './wiz';

export const store = configureStore({
  reducer: {
    auction: auctionReducer,
    start: startReducer,
    wiz: wizReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
