import classNames from 'classnames';
import { ReactNode } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useClickOutside from '../../../hooks/useClickOutside';
import { useNavigate } from '../../../hooks/useNavigate';
import {
  AuthRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';
import './MobileTab.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../stores';
import { setIsOpenLevelModal } from '../../../stores/subject';
import useCurrentSubjectData from '../../../hooks/useCurrentSubjectData';
import { saveCurrentUrl } from '../../../api/sessions';

interface MobileTabProps {
  className?: string;
  icon?: ReactNode;
  title?: string;
  url?: string;
  items?: RoutesType[];
  secondary?: ReactNode;
  component?: ReactNode;
  auth?: boolean;
}

export const MobileTab = ({
  className,
  icon,
  title,
  url,
  items,
  secondary = false,
  component,
  auth,
}: MobileTabProps) => {
  const location = useLocation();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [open, setRef, toggleOpen] = useClickOutside(false);
  const { lang } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const isMapPage = useSelector((state: RootState) => state.start.isMapPage);
  const subjectData = useCurrentSubjectData();

  const goTo = (url: string) => {
    if (url === MainRoutes.start && isMapPage && !subjectData?.isLevelCompleted) {
      if (!user) {
        saveCurrentUrl(window.location.pathname);
        navigate(MainRoutes.auth + AuthRoutes.signin);
        return;
      }
      dispatch(setIsOpenLevelModal(true));
    } else if (auth && !user?.guid) {
      navigate(MainRoutes.auth + AuthRoutes.signin);
    } else {
      navigate(url);
    }
  };

  const activeRoute = () => {
    if (url === `/` && location.pathname === `/${lang}/`) {
      return true;
    } else {
      return url && url !== `/` && location.pathname.includes(url);
    }
  };

  return (
    <div
      ref={setRef}
      className={classNames(
        'MobileTab',
        className,
        { active: activeRoute() || open },
        { secondary: secondary }
      )}
      onClick={() => (url ? goTo(url) : toggleOpen())}
    >
      {title === 'WIZ' && (
        <div
          className="absolute  top-0 h-2 w-2 -translate-x-1/2 transform text-nowrap rounded-full bg-red-500"
          style={{ left: 'calc(50% + 0.6rem)' }}
        ></div>
      )}
      {open && (
        <div
          className={classNames('MobileTab__collapse', {
            active: activeRoute() || open,
          })}
        >
          {items?.map((item, idx) => (
            <div
              onClick={() => goTo(item.path)}
              key={idx}
              className="MobileTab__item"
            >
              <div className="icon">{item.icon}</div>
              <div className="flex flex-col">
                <h5>{item.name}</h5>
                {item.component}
              </div>
            </div>
          ))}
          {component}
        </div>
      )}
      <div
        className={classNames(
          'MobileTab__icon',
          { active: activeRoute() || open },
          { secondary: secondary }
        )}
      >
        {className !== 'menu-play' && <>{icon}</>}
      </div>
      {className !== 'menu-play' && <>{title}</>}
    </div>
  );
};
