import { useTranslation } from 'react-i18next';
import { TQuestionsData } from '../../../../api/wiz/wiz';

interface QuestionContentProps {
  currentQuestion: TQuestionsData;
}

export const QuestionContent = ({ currentQuestion }: QuestionContentProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <div className="flex w-full items-center justify-between gap-6 self-stretch text-sm font-semibold text-secondary-dark-grey-500 md:text-base">
      <div className="flex w-full flex-col lg:gap-2">
        <div className="flex items-center justify-between gap-2">
          <p>
            <span className="inline lg-max:hidden">{t('wiz.Category')}: </span>
            {currentQuestion.category.title}
          </p>
          <img
            src={currentQuestion.category.image}
            className="block h-8 w-8 flex-none self-start fill-primary-purple-blue-500 lg:!hidden"
            alt="icon"
          />
        </div>
        <h1 className="self-stretch text-2xl text-[18px] font-semibold leading-none text-black md:text-3xl">
          <span className="inline lg-max:hidden">{t('wiz.Question')}: </span>
          {currentQuestion.text}
        </h1>
      </div>
      <img
        src={currentQuestion.category.image}
        className="!hidden flex-none self-start fill-primary-purple-blue-500 lg:!block lg:h-[60px] lg:w-[60px]"
        alt="icon"
      />
    </div>
  );
};
