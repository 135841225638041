import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getPrivateAds } from '../api/tasks';
import { TAdBack } from '../models/Tasks';
import {
  BLACKLIST_EXCLUDED_AD_IDS,
  MAX_RECEIVE_AD_ATTEMPTS,
} from '../modules/Tasks/constants';

interface Ad {
  exchange_ad_id: number;
  image_href: string;
  title: string;
}

interface AdsContextProps {
  ads: Ad[];
  setAds: (ads: Ad[]) => void;
  adsBack: TAdBack[];
  setAdsBack: (ads: TAdBack[]) => void;
}

const AdsContext = createContext<AdsContextProps | undefined>(undefined);

export const useAdsProvider = () => {
  const context = useContext(AdsContext);
  if (!context) {
    throw new Error('useAdsProvider must be used within an AdsProvider');
  }
  return context;
};

interface AdsProviderProps {
  children: ReactNode;
}
export const AdsProvider: React.FC<AdsProviderProps> = ({ children }) => {
  const [ads, setAds] = useState<Ad[]>([]);
  const [adsBack, setAdsBack] = useState<TAdBack[]>([]);
  const receiveAdAttemptsRef = useRef(0);

  useEffect(() => {
    if (!window.BarzhaExchange) {
      console.error('BarzhaExchange is not loaded. Check the script connection.');
      return;
    }

    const adsProvider = new window.BarzhaExchange(19, {
      onAdsReceive: (data: Ad[]) => {
        if (BLACKLIST_EXCLUDED_AD_IDS.includes(data[0].exchange_ad_id)) {
          receiveAdAttemptsRef.current += 1;

          if (receiveAdAttemptsRef.current < MAX_RECEIVE_AD_ATTEMPTS) {
            adsProvider.receiveAd();
          } else {
            setAds([]);
            receiveAdAttemptsRef.current = 0;
          }
        } else {
          setAds(data);
          receiveAdAttemptsRef.current = 0;
        }
      },

      onAdsNotFound: () => {
        setAds([]);
      },
      onError: (error: any) => {
        console.error('BarzhaExchange error:', error);
      },
    });

    window.WidgetInstance = adsProvider;
    window.WidgetInstance.receiveAd();
    (async () => {
      try {
        const data = await getPrivateAds();
        setAdsBack(data);
      } catch (error) {
        console.error('Error fetching ads:', error);
      }
    })();

    return () => {
      setAds([]);
      setAdsBack([]);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      ads,
      setAds,
      adsBack,
      setAdsBack,
    }),
    [ads, adsBack]
  );

  return <AdsContext.Provider value={contextValue}>{children}</AdsContext.Provider>;
};
