import { useEffect } from 'react';

export const useReferralCode = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const refCode = searchParams.get('ref-code');

    if (refCode) {
      sessionStorage.setItem('refCode', refCode);
    }
  }, []);
};
