import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FireIcon } from '../../assets/icons/wiz/fire.svg';
import { BottomSheet } from '../../horizon-components/bottom-sheet/ui';
import { MButton } from '../../horizon-components/MButton';
import { Modal } from '../../horizon-components/Modal';
import { useWizContext } from '../../providers/WizProvider/WizProvider';
import { QUIZ_COUNT } from '../../shared/const/wiz';
import { QuizStartModal } from '../../widgets/QuizStartModal';

interface WizDayProps {
  active?: boolean;
}

export const WizDay = ({ active = false }: WizDayProps) => {
  const { t } = useTranslation(['translation']);
  const [isStreakModalOpen, setIsStreakModalOpen] = useState(false);
  const { currentDay, currentMultiplier, currentTest, questions } = useWizContext();

  const count = questions?.length ? QUIZ_COUNT + 1 - questions.length : QUIZ_COUNT;

  return (
    <>
      {!currentTest ? (
        <MButton
          variant={active ? 'highlighted' : 'secondary'}
          color="primary"
          size="xs"
          className="!rounded-full"
          onClick={() => setIsStreakModalOpen(true)}
        >
          {currentDay} <span className="sm-max:hidden">{t('wiz.days')}</span>{' '}
          <FireIcon />
        </MButton>
      ) : questions ? (
        <span
          className={'ml-1.5 text-base font-semibold text-purple-600 lg:text-xl'}
        >
          {t('wiz.Question')} {count}/{QUIZ_COUNT}
        </span>
      ) : null}
      {window.innerWidth > 768 ? (
        <Modal
          isOpen={isStreakModalOpen}
          onClose={() => setIsStreakModalOpen(false)}
          showClose={false}
        >
          <QuizStartModal
            currentDayNumber={currentDay}
            multiplier={currentMultiplier}
            onStart={() => setIsStreakModalOpen(false)}
          />
        </Modal>
      ) : (
        <BottomSheet
          open={isStreakModalOpen}
          onDismiss={() => setIsStreakModalOpen(false)}
        >
          <QuizStartModal
            currentDayNumber={currentDay}
            multiplier={currentMultiplier}
            onStart={() => setIsStreakModalOpen(false)}
          />
        </BottomSheet>
      )}
    </>
  );
};
