import { useTranslation } from 'react-i18next';
import { MButton } from '../../horizon-components/MButton';
import { Modal } from '../../horizon-components/Modal';
import InstanceIcon from '../../assets/images/wiz/Instance.webp';

interface ExitConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ExitConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
}: ExitConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('wiz.Want_to_leave_the_game')}>
      <div className="flex flex-col items-center gap-4 ">
        <img src={InstanceIcon} alt="" />
        <p className="text-center">
          {t('wiz.Match_will_continue_and_your_bid_will_burn')}
        </p>
        <div className="grid grid-cols-2 gap-2">
          <MButton
            variant="transparent"
            size="sm"
            className="h-[!36px] w-[156px]"
            color="danger"
            onClick={onConfirm}
          >
            {t('buttons.Log_Out')}
          </MButton>
          <MButton variant="transparent" size="sm" color="primary" onClick={onClose}>
            {t('buttons.Stay')}
          </MButton>
        </div>
      </div>
    </Modal>
  );
};
