import classNames from 'classnames';
import { TSubjectMetrica } from '../../../models/Course';
import useFetch from '../../../hooks/useFetch';
import { getSubjectsListV3 } from '../../../api/courseV3';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader';
import { useUserContext } from '../../../providers';

interface ProfileSubjectsStatsProps {
  className?: string;
  metric?: TSubjectMetrica | null;
}

export const ProfileSubjectsStats = ({
  className,
  metric,
}: ProfileSubjectsStatsProps) => {
  const { t, i18n } = useTranslation(['translation']);
  const {
    data: subjects,
    isLoading: isSubjectsLoading,
    fetchData: fetchSubjects,
  } = useFetch(getSubjectsListV3);
  const { isCurrentUser } = useUserContext();

  useEffect(() => {
    fetchSubjects();
  }, [t]);

  return (
    <div
      className={classNames(
        'flex h-full flex-col justify-between gap-2 lg:gap-3',
        className
      )}
    >
      {isSubjectsLoading ? (
        <Loader />
      ) : subjects?.length ? (
        subjects
          ?.filter((subject) => !subject.is_miscellaneous_subject)
          .map((subject, index) => {
            const currentMetric = metric?.metrics.filter(
              (metric) =>
                metric.subject === subject.id && metric.language === i18n.language
            );

            return (
              <div
                key={index}
                className="flex w-full flex-col items-center justify-between gap-[2px] rounded-lg"
              >
                <div className="flex w-full items-center justify-between gap-[14px]">
                  <div className="w-full text-xs font-semibold text-secondary-dark-grey-900 lg:text-sm">
                    {subject.title}
                  </div>
                  <div className="hidden w-full items-center gap-4 text-center lg:flex">
                    <div
                      className={classNames(
                        'relative flex h-5 w-full items-center overflow-hidden rounded-full',
                        {
                          'bg-secondary-grey-300': isCurrentUser,
                          'bg-primary-purple-blue-100': !isCurrentUser,
                        }
                      )}
                    >
                      <div
                        className={classNames('h-full', {
                          'bg-primary-green-300': isCurrentUser,
                          'bg-primary-purple-blue-200': !isCurrentUser,
                        })}
                        style={{
                          width: `${((currentMetric?.[currentMetric.length - 1]?.progress || 0) * 100).toFixed(2)}%`,
                        }}
                      ></div>
                      {currentMetric?.[currentMetric.length - 1]?.grade ? (
                        <span className="absolute w-full text-[10px] font-semibold lowercase text-primary-green-900">
                          {currentMetric?.[currentMetric.length - 1]?.grade || 0}{' '}
                          {t('map.Level')}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <span className="text-xs font-semibold text-secondary-dark-grey-900 lg:text-sm">
                    {(currentMetric?.[currentMetric.length - 1]?.gpa || 0).toFixed(
                      1
                    )}
                  </span>
                </div>
                <div className="flex w-full items-center gap-4 text-center lg:hidden">
                  <div
                    className={classNames(
                      'relative flex h-5 w-full items-center overflow-hidden rounded-full',
                      {
                        'bg-secondary-grey-300': isCurrentUser,
                        'bg-primary-purple-blue-100': !isCurrentUser,
                      }
                    )}
                  >
                    <div
                      className={classNames('h-full', {
                        'bg-primary-green-300': isCurrentUser,
                        'bg-primary-purple-blue-200': !isCurrentUser,
                      })}
                      style={{
                        width: `${((currentMetric?.[0]?.progress || 0) * 100).toFixed(2)}%`,
                      }}
                    ></div>
                    {currentMetric?.[currentMetric.length - 1]?.grade ? (
                      <span className="absolute w-full text-[10px] font-semibold lowercase text-primary-green-900">
                        {currentMetric?.[currentMetric.length - 1]?.grade || 0}{' '}
                        {t('map.Level')}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })
      ) : null}
    </div>
  );
};
