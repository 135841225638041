import { Share, ShareOptions } from '@capacitor/share';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { PlatformsEnum } from '../entities/Platforms/platforms';
import { useUserContext } from '../providers';
import { useRewardContext } from '../providers/RewardProvider';
import getPlatform from '../utils/getPlatform';
import User from 'onesignal-cordova-plugin/dist/UserNamespace';

export const useShareReferal = () => {
  const { t, i18n } = useTranslation(['translation']);
  const { referral, isCurrentUser, user } = useUserContext();
  const { rewards } = useRewardContext();
  const platform = getPlatform();

  const activationRewardAmountRaw =
    (rewards &&
      rewards
        .find((reward) => reward.tokenomic_type === 'referral_code_activation')
        ?.value.toLocaleString()) ||
    '0';
  const activationRewardAmount = parseInt(
    activationRewardAmountRaw
  ).toLocaleString();

  const isMobile = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
  }, []);

  const prepareURL = useCallback((url: string, ref: string) => {
    const turl = new URL(url);
    const hostname = process.env.REACT_APP_API_URL?.replace('https://', '') || turl.hostname;
    const anotherUrl = new URL(window.location.href.replace('localhost', hostname));
    turl.hostname = hostname;
    turl.searchParams.append('ref-code', ref);
    anotherUrl.searchParams.append('ref-code', ref);
    return isCurrentUser ? turl.href : anotherUrl.href;
  }, []);
  const prepareTelegramURL = useCallback((ref: string) => {
    return `https://t.me/${process.env.REACT_APP_TG_BOT_NAME}/${process.env.REACT_APP_TG_APP_NAME}?startapp=refid${ref}`;
  }, []);

  const handleShare = useCallback(async () => {
    if (!referral) return;

    if (platform === PlatformsEnum.TELEGRAM) {
      // const shareTGurl =  `https://t.me/${process.env.REACT_APP_TG_BOT_NAME}/${process.env.REACT_APP_TG_APP_NAME}?startapp=refid${referral}`;
      const botURL = encodeURI(
        `https://t.me/${process.env.REACT_APP_TG_BOT_NAME}/${process.env.REACT_APP_TG_APP_NAME}?startapp=refid${referral}`
      );
      let shareText = '';
      if (window.Telegram!.WebApp.platform !== 'ios') {
        shareText = `&text=${t('main.share.description', { activationRewardAmount })}`;
      }
      window.Telegram!.WebApp.openLink(
        `https://telegram.me/share/url?url=${botURL}${shareText}`,
        {
          try_instant_view: false,
        }
      );
    } else if (isMobile() && navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: `${t('main.share.description', { activationRewardAmount })} ${
            referral
              ? prepareURL(window.location.href, referral)
              : window.location.href
          }`,
          // url: prepareURL(window.location.href, referral),
        });
      } catch (err) {
        console.error('Failed to share:', err);
      }
      
    } else if (
      platform === PlatformsEnum.IOS ||
      platform === PlatformsEnum.ANDROID
    ) {
      const shareParams: ShareOptions = {
        title: document.title,
        text: `${t('main.share.description', { activationRewardAmount })} ${
          referral
            ? prepareURL(window.location.href, referral)
            : window.location.href
        }`,
      };
      if (platform === PlatformsEnum.ANDROID) {
        shareParams.dialogTitle = t('main.Share_Edres_for_friends');
      }
      await Share.share(shareParams);
    } else {
      const domain = `${window.location.protocol}//${window.location.hostname}`;
      try {
        await navigator.clipboard.writeText(
          referral
            ? prepareURL(`${domain}/${i18n.language}/`, referral)
            : window.location.href
        );
        toast(t('main.The_referral_has_been_copied'), { type: 'info' });
      } catch (err) {
        console.error('Failed to write to clipboard:', err);
      }
    }
  }, [isMobile, prepareURL, referral, t, activationRewardAmount]);

  const handleClick = useCallback(() => {
    handleShare();
  }, [handleShare]);
  const isShareDisabled = useMemo(() => {
    return !referral || !user?.guid;
  }, [referral, user, handleShare]);

  return {
    handleClick,
    url:
      platform === PlatformsEnum.TELEGRAM
        ? referral
          ? prepareTelegramURL(referral)
          : ''
        : referral
          ? prepareURL(window.location.href, referral)
          : '',
    activationRewardAmount,
    isShareDisabled,
  };
};