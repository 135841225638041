import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../../horizon-components/MButton';
import { CoinIcon } from '../../../../components/CoinIcon';
import { NoAvatar } from '../../../../widgets/NoAvatar';
import { useRandomEmoji } from '../../hooks/useRandomEmoji';
import { useUserContext } from '../../../../providers';
import { useAppDispatch, useAppSelector } from '../../../../stores/hooks';
import { getUserSomeDataStorage, removeUserSomeDataStorage } from '../../../../api';
import { useEffect, useState } from 'react';
import { getPvPQuizInfoAsync } from '../../../../stores/wiz';
import { MatchViewStatus } from '../types';
import { Loader } from '../../../../components/Loader';
import { sendPvpWizCompletion } from '../../../../hooks/useAnalytics';

interface WizMainResultProps {
  className?: string;
  onClose?: () => void;
}

export const WizMainResult = ({ className, onClose }: WizMainResultProps) => {
  const { t } = useTranslation(['translation']);
  const { user, updateWallet } = useUserContext();
  const dispatch = useAppDispatch();
  const matchViewStatus: MatchViewStatus = getUserSomeDataStorage(
    'matchViewStatus',
    user?.guid
  );

  useEffect(() => {
    if (gameResult) {
      const emoji = getRandomEmoji(gameResult);
      setRandomEmoji(emoji);
    }
  }, []);

  useEffect(() => {
    updateWallet();
  }, []);

  useEffect(() => {
    dispatch(getPvPQuizInfoAsync(parseInt(matchViewStatus.matchId)));
  }, []);

  const { getRandomEmoji } = useRandomEmoji();
  const [randomEmoji, setRandomEmoji] = useState<string | null>(null);
  const { pvpQuizInfo } = useAppSelector((state) => state.wiz);
  const [isImageLoading, setIsImageLoading] = useState(true);

  if (!pvpQuizInfo) return null;
  removeUserSomeDataStorage('matchViewStatus', user?.guid);
  const gameResult =
    pvpQuizInfo.winner === null
      ? 'draw'
      : pvpQuizInfo.winner === user?.guid
        ? 'win'
        : 'lost';

  const getBgColor = () => {
    switch (gameResult) {
      case 'win':
        return 'bg-primary-green-600';
      case 'lost':
        return 'bg-secondary-grey-700';
      case 'draw':
        return 'bg-primary-orange-600';
    }
  };

  const getTitle = () => {
    switch (gameResult) {
      case 'win':
        return t('wiz.Youve_won');
      case 'lost':
        return t('wiz.Youve_lost');
      case 'draw':
        return t('wiz.Draw');
    }
  };

  const getButtonText = () => {
    return gameResult === 'win'
      ? t('buttons.Collect_the_reward')
      : t('buttons.Play_it_again');
  };

  const formatTime = (completedAt: string | null, startedAt: string) => {
    if (!completedAt) return '5:00:000';

    const start = new Date(startedAt);
    const end = new Date(completedAt);
    const diffMilliseconds = end.getTime() - start.getTime();
    const diffSeconds = Math.floor(diffMilliseconds / 1000);
    const minutes = Math.floor(diffSeconds / 60);
    const seconds = diffSeconds % 60;
    const milliseconds = Math.floor((diffMilliseconds % 1000) / 10);
    return `${minutes}:${seconds.toString().padStart(2, '0')}:${milliseconds.toString().padStart(3, '0')}`;
  };

  const players = [
    {
      username: pvpQuizInfo?.participants.find(
        (participant) => participant.guid === pvpQuizInfo.initiator
      )?.username,
      user_guid: pvpQuizInfo.initiator,
      correct_answers: pvpQuizInfo.initiator_correct_answers || 0,
      completed_at: pvpQuizInfo.initiator_completed_at,
      profile_image: pvpQuizInfo?.participants.find(
        (participant) => participant.guid === pvpQuizInfo.initiator
      )?.profile_image,
    },
    {
      username: pvpQuizInfo?.participants.find(
        (participant) => participant.guid === pvpQuizInfo.opponent
      )?.username,
      user_guid: pvpQuizInfo.opponent,
      correct_answers: pvpQuizInfo.opponent_correct_answers || 0,
      completed_at: pvpQuizInfo.opponent_completed_at,
      profile_image: pvpQuizInfo?.participants.find(
        (participant) => participant.guid === pvpQuizInfo.opponent
      )?.profile_image,
    },
  ];

  return (
    <div
      className={classNames(
        'absolute bottom-0 left-0 right-0 top-0 z-[60] flex h-screen w-screen flex-col p-2',
        getBgColor(),
        className
      )}
    >
      <div
        className="mb-5 flex h-full flex-col gap-4"
        style={{ marginTop: 'calc(var(--safe-area-top-height))' }}
      >
        <div className="mt-10 flex flex-col justify-end gap-4 text-center text-white">
          <h4 className="text-xl font-bold">{getTitle()}</h4>
          {randomEmoji ? (
            <img
              src={randomEmoji}
              className="mx-auto hidden h-[100px] w-[100px] tall:block"
              alt={t('wiz.We_are_waiting_for_the_game_to_end')}
              onLoad={() => setIsImageLoading(false)}
              onError={() => setIsImageLoading(false)}
            />
          ) : isImageLoading ? (
            <Loader className="mx-auto hidden h-[100px] w-[100px] tall:block" />
          ) : null}
        </div>
        <div className="flex justify-between rounded-2xl border border-primary-purple-blue-200 bg-primary-purple-blue-100 p-4 text-sm font-semibold text-primary-purple-blue-700">
          <span>{t('wiz.You_received')}</span>
          <span>
            {gameResult === 'win' ? Number(pvpQuizInfo.prize_amount).toFixed(0) : 0}{' '}
            <CoinIcon className="h-4 fill-primary-purple-blue-700" />
          </span>
        </div>

        {players.map((player, index) => (
          <div
            key={index}
            className="flex flex-col gap-4 rounded-2xl border border-primary-purple-blue-200 bg-primary-purple-blue-100 p-4 text-sm font-semibold text-secondary-grey-900"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3 truncate text-center">
                <NoAvatar
                  src={player.profile_image || undefined}
                  alt={''}
                  className="relative m-auto !h-[34px] !w-[34px] rounded-full"
                />
                <h5 className="truncate text-sm font-bold">{player.username}</h5>
              </div>
              {gameResult !== 'draw' && (
                <span
                  className={classNames(
                    'text-[10px] font-bold uppercase',
                    player.user_guid === pvpQuizInfo.winner
                      ? 'text-primary-green-600'
                      : 'text-primary-red-400'
                  )}
                >
                  {player.user_guid === pvpQuizInfo.winner
                    ? t('wiz.Winner')
                    : t('wiz.Loser')}
                </span>
              )}
            </div>
            <div className="flex items-center justify-between">
              <span>{t('wiz.Correct_answers')}:</span>
              <span>{player.correct_answers}/10</span>
            </div>
            <div className="flex items-center justify-between">
              <span>{t('wiz.Transit_time')}:</span>
              <span>{formatTime(player.completed_at, pvpQuizInfo.started_at)}</span>
            </div>
          </div>
        ))}
      </div>
      <div
        className="flex flex-col gap-2"
        style={{ marginBottom: 'calc(var(--safe-area-bottom-height))' }}
      >
        <MButton
          className="!h-12"
          onClick={() => {
            sendPvpWizCompletion();
            onClose?.();
          }}
          variant="secondary"
          color="primary"
          size="xs"
        >
          {getButtonText()}!
        </MButton>
      </div>
    </div>
  );
};
