import { useTranslation } from 'react-i18next';
import { MButton } from '../../../../horizon-components/MButton';

interface WizFooterButtonsProps {
  onRules: () => void;
  onClose?: () => void;
}

export const WizFooterButtons = ({ onRules, onClose }: WizFooterButtonsProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <div
      className="flex flex-col gap-2"
      style={{ marginBottom: 'calc(var(--safe-area-bottom-height))' }}
    >
      <MButton
        className="!h-12"
        onClick={onRules}
        variant="highlighted"
        color="primary"
        size="xs"
      >
        {t('wiz.Rules')}
      </MButton>
      <MButton
        className="!h-12"
        onClick={onClose}
        variant="secondary"
        color="primary"
        size="xs"
      >
        {t('buttons.Close')}
      </MButton>
    </div>
  );
};
