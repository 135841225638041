import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getPvPQuizConnect,
  getPvPQuizInfo,
  setStartPvPQuiz,
  TPvPBids,
  TPvPQuizInfo,
  TStartPvPQuiz,
} from '../api/wiz/wiz';

interface WizState {
  gameState: string | null;
  startPvpQuiz: TStartPvPQuiz | null;
  startPvpQuizLoading: boolean;
  startPvpQuizError: string | null;
  pvpQuizInfo: TPvPQuizInfo | null;
  pvpQuizInfoLoading: boolean;
  pvpQuizInfoError: string | null;
  userBids: { type: string; price: number }[];
}

const initialState: WizState = {
  gameState: null,
  startPvpQuiz: null,
  startPvpQuizLoading: false,
  startPvpQuizError: null,
  pvpQuizInfo: null,
  pvpQuizInfoLoading: false,
  pvpQuizInfoError: null,
  userBids: [],
};

export const startPvPQuizAsync = createAsyncThunk(
  'wiz/startPvPQuiz',
  async (bids: TPvPBids) => {
    const response = await setStartPvPQuiz(bids);
    return response;
  }
);

export const checkPvPQuizConnectAsync = createAsyncThunk(
  'wiz/checkPvPQuizConnect',
  async (pvpQuizId: number) => {
    const response = await getPvPQuizConnect(pvpQuizId);
    return response;
  }
);

export const getPvPQuizInfoAsync = createAsyncThunk(
  'wiz/getPvPQuizInfo',
  async (pvpQuizId: number) => {
    const response = await getPvPQuizInfo(pvpQuizId);
    return response;
  }
);

export const wizSlice = createSlice({
  name: 'wiz',
  initialState,
  reducers: {
    setUserBids: (
      state,
      action: PayloadAction<{ type: string; price: number }[]>
    ) => {
      state.userBids = action.payload;
    },
    setGameState: (state, action: PayloadAction<string | null>) => {
      state.gameState = action.payload;
    },
    setStartPvPQuiz: (state, action: PayloadAction<TStartPvPQuiz | null>) => {
      state.startPvpQuiz = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startPvPQuizAsync.pending, (state: WizState) => {
        state.startPvpQuizLoading = true;
        state.startPvpQuizError = null;
      })
      .addCase(
        startPvPQuizAsync.fulfilled,
        (state: WizState, action: PayloadAction<TStartPvPQuiz>) => {
          state.startPvpQuizLoading = false;
          state.startPvpQuiz = action.payload;
        }
      )
      .addCase(
        startPvPQuizAsync.rejected,
        (
          state: WizState,
          action: PayloadAction<unknown, string, never, { message?: string }>
        ) => {
          state.startPvpQuizLoading = false;
          state.startPvpQuizError =
            action.error.message || 'Failed to start PvP quiz';
          state.gameState = null;
        }
      )
      .addCase(
        checkPvPQuizConnectAsync.fulfilled,
        (state: WizState, action: PayloadAction<TPvPQuizInfo>) => {
          if (action.payload.opponent) {
            state.gameState = 'start';
            state.pvpQuizInfo = action.payload;
          }
        }
      )
      .addCase(getPvPQuizInfoAsync.pending, (state: WizState) => {
        state.pvpQuizInfoLoading = true;
        state.pvpQuizInfoError = null;
      })
      .addCase(
        getPvPQuizInfoAsync.fulfilled,
        (state: WizState, action: PayloadAction<TPvPQuizInfo>) => {
          state.pvpQuizInfoLoading = false;
          state.pvpQuizInfo = action.payload;
        }
      )
      .addCase(
        getPvPQuizInfoAsync.rejected,
        (
          state: WizState,
          action: PayloadAction<unknown, string, never, { message?: string }>
        ) => {
          state.pvpQuizInfoLoading = false;
          state.pvpQuizInfoError =
            action.error.message || 'Failed to get PvP quiz info';
        }
      );
  },
});

export const { setGameState, setUserBids } = wizSlice.actions;
export default wizSlice.reducer;
