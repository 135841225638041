import { useTranslation } from 'react-i18next';
import { Modal } from '../../horizon-components/Modal';
import { BottomSheet } from '../../horizon-components/bottom-sheet/ui';
import { useRewardContext } from '../../providers/RewardProvider';

interface WizRulesModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const WizRulesModal = ({ isOpen, onClose }: WizRulesModalProps) => {
  const { t } = useTranslation(['translation']);
  const { rewards } = useRewardContext();
  const pvpQuizLow = Math.floor(
    Number(
      rewards?.find((reward) => reward.tokenomic_type === 'pvp_quiz_low')?.value ??
        100
    )
  );
  const pvpQuizMax = Math.floor(
    Number(
      rewards?.find((reward) => reward.tokenomic_type === 'pvp_quiz_max')?.value ??
        100000
    )
  );
  const pvpQuizCommisionPercent = rewards?.find(
    (reward) => reward.tokenomic_type === 'pvp_quiz_commission_percent'
  )?.value
    ? Number(
        rewards.find(
          (reward) => reward.tokenomic_type === 'pvp_quiz_commission_percent'
        )?.value
      ) * 2
    : 20;
  const coefficient = 2 - Number(pvpQuizCommisionPercent) / 100;

  const rules = [
    {
      title: t('wiz.rules.1', {
        minBet: pvpQuizLow,
        maxBet: pvpQuizMax,
      }),
    },
    { title: t('wiz.rules.2') },
    { title: t('wiz.rules.3') },
    { title: t('wiz.rules.4') },
    { title: t('wiz.rules.5') },
    { title: t('wiz.rules.6') },
    {
      title: t('wiz.rules.7'),
    },
    {
      title: t('wiz.rules.8', {
        coefficient: coefficient,
      }),
    },
  ];

  const renderContent = () => (
    <div className="flex w-full flex-col gap-5">
      <h4 className="text-center text-lg font-bold text-secondary-grey-900 lg:hidden lg:text-left">
        {t('wiz.Rules')}
      </h4>
      {rules.map((rule, idx) => (
        <div
          key={idx}
          className="flex gap-2.5 text-sm font-semibold text-secondary-grey-900"
        >
          <span className="flex !h-6 !w-6 items-center justify-center rounded-full bg-primary-purple-blue-100 p-1 font-bold">
            {idx + 1}
          </span>{' '}
          <p>{rule.title}</p>
        </div>
      ))}
    </div>
  );

  return window.innerWidth > 768 ? (
    <Modal isOpen={isOpen} onClose={onClose} title={t('wiz.Rules')}>
      {renderContent()}
    </Modal>
  ) : (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      {renderContent()}
    </BottomSheet>
  );
};
