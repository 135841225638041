import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { WizEnergy } from '../../../../features/wiz-energy';
import { WizDay } from '../../../../features/wiz-day';
import { MButton } from '../../../../horizon-components/MButton';
import { ReactComponent as PlayIcon } from '../../../../assets/icons/play.svg';
import { useNavigate } from '../../../../hooks/useNavigate';
import {
  MainRoutes,
  WizRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import useFetch from '../../../../hooks/useFetch';
import { getQuestionCategories } from '../../../../api/wiz/wiz';
import { useEffect, useState } from 'react';
import { useWizContext } from '../../../../providers';
import { BottomSheet } from '../../../../horizon-components/bottom-sheet/ui';
import { QuizTestModal } from '../../../../widgets/QuizTestModal';
import { Modal } from '../../../../horizon-components/Modal';
import { Loader } from '../../../../components/Loader';

interface WizMainClassicProps {
  className?: string;
}

export const WizMainClassic = ({ className }: WizMainClassicProps) => {
  const { t, i18n } = useTranslation(['translation']);
  const [isEnergyModalOpen, setIsEnergyModalOpen] = useState(false);
  const {
    data,
    isLoading,
    fetchData: fetchCategories,
  } = useFetch(getQuestionCategories);
  const { livesCount, fetchStartQuiz, questions, currentTest } = useWizContext();
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories(i18n.language);
  }, [t]);

  const handlePlayClick = async () => {
    if (data?.results.length) {
      if (isFetching) return;
      if (questions && currentTest) {
        navigate(`${MainRoutes.wiz}${WizRoutes.quiz}`);
      }
      setIsFetching(true);

      if (livesCount === 0) {
        setIsEnergyModalOpen(true);
      } else {
        await fetchStartQuiz(data?.results[0].id);
        navigate(`${MainRoutes.wiz}${WizRoutes.quiz}`);
      }
      setIsFetching(false);
    }
  };

  return (
    <div>
      <div
        className={classNames(
          'flex flex-col gap-3 rounded-3xl border border-primary-purple-blue-100 p-3',
          className
        )}
      >
        <div className="flex items-center justify-between gap-3">
          <span className="text-base font-semibold text-primary-purple-blue-600">
            {t('wiz.Classic_WIZ')}
          </span>
          <div className="flex items-center justify-between gap-2">
            <WizDay />
            <WizEnergy />
          </div>
        </div>
        <MButton
          variant="highlighted"
          color="primary"
          size="xs"
          className="!rounded-full"
          onClick={handlePlayClick}
        >
          {t('buttons.Play')}
          <PlayIcon />
        </MButton>
      </div>
      {isLoading ? (
        <Loader />
      ) : window.innerWidth > 768 ? (
        <Modal
          isOpen={isEnergyModalOpen}
          onClose={() => setIsEnergyModalOpen(false)}
          showClose={false}
        >
          <QuizTestModal onClose={() => setIsEnergyModalOpen(false)} />
        </Modal>
      ) : (
        <BottomSheet
          open={isEnergyModalOpen}
          onDismiss={() => setIsEnergyModalOpen(false)}
        >
          <QuizTestModal onClose={() => setIsEnergyModalOpen(false)} />
        </BottomSheet>
      )}
    </div>
  );
};
