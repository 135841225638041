import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Capacitor } from '@capacitor/core';
import getPlatform from '../utils/getPlatform';
import { AnalyticsEventsEnum } from '../shared/const/analytics-events';

const yandexMetrikaId = process.env.REACT_APP_YANDEX_METRICA_ID || '';
const facebookPixelID = process.env.REACT_APP_FACEBOOK_PIXEL_ID || '';
declare global {
  interface Window {
    fbq: any;
    ym: any;
  }
}
interface EventParams {
  [key: string]: any;
}

export const setUserID = (userID: string = '') => {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.set({ userId: userID });

  if (window.ym) {
    window.ym(yandexMetrikaId, 'setUserID', userID);
  }
  if (window.fbq) {
    window.fbq('init', facebookPixelID, {
      external_id: userID,
    });
  }
};

export const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    // if (window.hasOwnProperty('Telegram')) {
    //   return
    // }
    // ReactGA.send({ hitType: "pageview", page: window.location.href});
    if (window.ym) {
      window.ym(yandexMetrikaId, 'hit', window.location.href);
    }
    if (window.fbq) {
      window.fbq('track', AnalyticsEventsEnum.PAGE_VIEW);
    }
  }, [location]);
};

export const sendShare = (
  method: string = '',
  content_type: string = '',
  item_id: string = ''
) => {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  if (window.gtag) {
    ReactGA.gtag('event', AnalyticsEventsEnum.SHARE, {
      method: method,
      content_type: content_type,
      item_id: item_id,
    });
  }
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.SHARE);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.SHARE, {
      method: method,
      content_type: content_type,
      item_id: item_id,
    });
  }
};

export const sendSignUp = (method: string = '') => {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', AnalyticsEventsEnum.SIGN_UP, {
    method: method,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.SIGN_UP);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.SIGN_UP, {
      method: method,
    });
  }
};

export const sendLogin = function (method: string = '') {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', AnalyticsEventsEnum.LOGIN, {
    method: method,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.LOGIN);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.LOGIN, {
      method: method,
    });
  }
};
// send information that mobile app was opened
export const sendOpenMobile = function (platform: string = '') {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', AnalyticsEventsEnum.OPEN_MOBILE, {
    platform: platform,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.OPEN_MOBILE);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.OPEN_MOBILE, {
      platform: platform,
    });
  }
};
export const sendOpenMobileIfNative = () => {
  if (Capacitor.isNativePlatform()) {
    sendOpenMobile(Capacitor.getPlatform());
  }
};
export const sendCreationComplite = function (
  unitId: any = undefined,
  unitTitle: string = ''
) {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', AnalyticsEventsEnum.COURSE_CREATION_START, {
    unitId: unitId,
    unitTitle: unitTitle,
  });
  if (window.ym) {
    window.ym(
      yandexMetrikaId,
      'reachGoal',
      AnalyticsEventsEnum.COURSE_CREATION_START
    );
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.COURSE_CREATION_START, {
      unitId: unitId,
      unitTitle: unitTitle,
    });
  }
};
export const sendCreationStart = function () {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', AnalyticsEventsEnum.COURSE_CREATION_START, {});
  if (window.ym) {
    window.ym(
      yandexMetrikaId,
      'reachGoal',
      AnalyticsEventsEnum.COURSE_CREATION_START
    );
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.COURSE_CREATION_START, {});
  }
};
// TODO add course info to function values
export const sendCoursePurchase = function () {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', AnalyticsEventsEnum.COURSE_PURCHASE, {});
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.COURSE_PURCHASE);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.COURSE_PURCHASE, {});
  }
};
// TODO add course info to function values
export const sendCourseCompletion = function () {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', AnalyticsEventsEnum.COURSE_COMPLETION, {});
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.COURSE_COMPLETION);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.COURSE_COMPLETION, {});
  }
};
export const sendBid = () => {
  ReactGA.gtag('event', AnalyticsEventsEnum.BID);
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.BID);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.BID);
  }
};

export const sendWizCompletion = () => {
  ReactGA.gtag('event', AnalyticsEventsEnum.WIZ_COMPLETE);
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.WIZ_COMPLETE);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.WIZ_COMPLETE);
  }
};

export const sendWizStart = () => {
  ReactGA.gtag('event', AnalyticsEventsEnum.WIZ_START);
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.WIZ_START);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.WIZ_START);
  }
};

export const sendPvpWizCompletion = () => {
  ReactGA.gtag('event', AnalyticsEventsEnum.PVP_WIZ_COMPLETE);
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.PVP_WIZ_COMPLETE);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.PVP_WIZ_COMPLETE);
  }
};

export const sendPvpWizStart = () => {
  ReactGA.gtag('event', AnalyticsEventsEnum.PVP_WIZ_START);
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.PVP_WIZ_START);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.PVP_WIZ_START);
  }
};

export const sendRewardReceived = () => {
  ReactGA.gtag('event', AnalyticsEventsEnum.REWARD_RECEIVED);
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.REWARD_RECEIVED);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.REWARD_RECEIVED);
  }
};

export const sendEarnEDRES = function (value: number = 0) {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', AnalyticsEventsEnum.EARN_VIRTUAL_CURRENCY, {
    virtual_currency_name: 'EdRes',
    value: value,
  });
  if (window.ym) {
    window.ym(
      yandexMetrikaId,
      'reachGoal',
      AnalyticsEventsEnum.EARN_VIRTUAL_CURRENCY
    );
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.EARN_VIRTUAL_CURRENCY, {
      virtual_currency_name: 'EdRes',
      value: value,
    });
  }
};
export const sendSpendEDRES = function (value: number = 0, item_name: string = '') {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', AnalyticsEventsEnum.SPEND_VIRTUAL_CURRENCY, {
    virtual_currency_name: 'EdRes',
    value: value,
    item_name: item_name,
  });
  if (window.ym) {
    window.ym(
      yandexMetrikaId,
      'reachGoal',
      AnalyticsEventsEnum.SPEND_VIRTUAL_CURRENCY
    );
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.SPEND_VIRTUAL_CURRENCY, {
      virtual_currency_name: 'EdRes',
      value: value,
      item_name: item_name,
    });
  }
};
export const sendSearch = function (search_term: string = '') {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', AnalyticsEventsEnum.SEARCH, {
    search_term: search_term,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.SEARCH);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.SEARCH, {
      search_term: search_term,
    });
  }
};
export const sendLevelStart = function (level_name: string = '') {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', AnalyticsEventsEnum.LEVEL_START, {
    level_name: level_name,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.LEVEL_START);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.LEVEL_START, {
      level_name: level_name,
    });
  }
};
export const sendLevelEnd = function (
  level_name: string = '',
  success: boolean = true
) {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', AnalyticsEventsEnum.LEVEL_END, {
    level_name: level_name,
    success: success,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', AnalyticsEventsEnum.LEVEL_END);
  }
  if (window.fbq) {
    window.fbq('trackCustom', AnalyticsEventsEnum.LEVEL_END, {
      level_name: level_name,
      success: success,
    });
  }
};
export const waitForAnalytics = () => {
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      if (ReactGA.isInitialized && window.ym && window.fbq) {
        resolve(undefined);
        clearInterval(intervalId);
      }
    }, 100);
  });
};

const IS_INSTALL_SENT_KEY = 'is_install_analytics_sent';
export const sendInstallIfFirstLaunch = () => {
  if (localStorage.getItem(IS_INSTALL_SENT_KEY)) return;
  const event = `install_${getPlatform()}`;

  ReactGA.gtag('event', event);
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', event);
  }
  if (window.fbq) {
    window.fbq('trackCustom', event);
  }
  localStorage.setItem(IS_INSTALL_SENT_KEY, 'YES');
};

export const sendLandingEvent = (prefix: string, eventName: string) => {
  const event = `${prefix}_${eventName}`;
  ReactGA.gtag('event', event);
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', event);
  }
  if (window.fbq) {
    window.fbq('trackCustom', event);
  }
};

const useAnalytics = () => {
  // Отправка события в Google Analytics
  const sendEventToGoogleAnalytics = (
    eventName: string,
    eventParams: EventParams = {}
  ) => {
    // if (window.hasOwnProperty('Telegram')) {
    //   return
    // }
    if (window.gtag) {
      window.gtag('event', eventName, eventParams);
    }
  };

  // Отправка события в Яндекс Метрику
  const sendEventToYandexMetrika = (
    eventName: string,
    eventParams: EventParams = {}
  ) => {
    // if (window.hasOwnProperty('Telegram')) {
    //   return
    // }
    const counter = (window as any)[`yaCounter${yandexMetrikaId}`];
    if (counter) {
      counter.reachGoal(eventName, eventParams);
    }
  };

  // Отправка события в обе системы аналитики
  const sendEvent = (eventName: string, eventParams: EventParams = {}) => {
    sendEventToGoogleAnalytics(eventName, eventParams);
    sendEventToYandexMetrika(eventName, eventParams);
  };

  return { sendEvent };
};

export default useAnalytics;
