import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../../horizon-components/MButton';
import { ReactComponent as PlayIcon } from '../../../../assets/icons/play.svg';
import { ReactComponent as QuestionIcon } from '../../../../assets/icons/wiz/question.svg';
import { WizRulesModal } from '../../../../widgets/wiz-rules-modal';
import { useEffect, useState } from 'react';
import { PvPCompletedCard } from '../../../../widgets/pvp-completed-card';
import { WizBidModal } from '../../../../widgets/wiz-bid-modal';
import { getPvPQuizHistory } from '../../../../api/wiz/wiz';
import useFetch from '../../../../hooks/useFetch';
import { Loader } from '../../../../components/Loader';
import { useUserContext } from '../../../../providers';
import { useNavigate } from '../../../../hooks/useNavigate';
import {
  AuthRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { saveCurrentUrl } from '../../../../api/sessions';
import { removeUserSomeDataStorage } from '../../../../api';

interface WizMainPvPProps {
  className?: string;
}

export const WizMainPvP = ({ className }: WizMainPvPProps) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const [isBidOpen, setIsBidOpen] = useState(false);
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const { startPvpQuiz, gameState } = useAppSelector((state) => state.wiz);

  const handleRules = () => {
    setIsRulesOpen(!isRulesOpen);
  };

  const checkBeforeBid = async () => {
    if (user?.guid === undefined) {
      if (!user) {
        saveCurrentUrl(window.location.pathname);
        navigate(MainRoutes.auth + AuthRoutes.signin);
        return;
      }
    }
    // const matchViewStatus: MatchViewStatus = getUserSomeDataStorage(
    //   'matchViewStatus',
    //   user?.guid
    // );
    // if (!matchViewStatus) {
    //   return;
    // }
    // if (!startPvpQuiz) {
    //   await dispatch(getPvPQuizInfoAsync(Number(matchViewStatus.matchId)));
    // }

    // if (
    //   matchViewStatus &&
    //   matchViewStatus.hasSubmittedAnswers &&
    //   !matchViewStatus.isViewedResult
    // ) {
    //   dispatch(setGameState('result'));
    //   return;
    // }
    // if (matchViewStatus && !matchViewStatus.hasSubmittedAnswers) {
    //   if (matchViewStatus.startedAt) {
    //     const startTime = new Date(matchViewStatus.startedAt).getTime();
    //     const currentTime = new Date().getTime();
    //     const elapsedSeconds = (currentTime - startTime) / 1000;

    //     if (elapsedSeconds > 310) {
    //       dispatch(setGameState('result'));
    //       return;
    //     }

    //     navigate(
    //       `${MainRoutes.wiz}${WizRoutes.quizzes}/match/${matchViewStatus.matchId}`
    //     );
    //   }
    // }
  };

  const handleBid = () => {
    removeUserSomeDataStorage('matchViewStatus', user?.guid);
    setIsBidOpen(!isBidOpen);
  };

  const { data, isLoading, fetchData } = useFetch(getPvPQuizHistory);

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  return (
    <>
      <div
        className={classNames(
          'flex flex-col gap-3 rounded-3xl border border-primary-purple-blue-100 p-3',
          className
        )}
      >
        <div className="flex items-center justify-between gap-3">
          <span className="text-base font-semibold text-primary-purple-blue-600">
            {t('wiz.PVP_mode')}
          </span>
          <MButton
            className="!rounded-full"
            variant="secondary"
            color="primary"
            size="xs"
            onClick={handleRules}
          >
            {t('wiz.Rules')}
            <QuestionIcon />
          </MButton>
        </div>
        <MButton
          className="!rounded-full"
          variant="highlighted"
          color="primary"
          size="xs"
          onClick={() => {
            checkBeforeBid();
            handleBid();
          }}
        >
          {t('weber.Join')}
          <PlayIcon />
        </MButton>

        <div className="flex flex-col gap-2">
          <h5 className="py-2 text-sm font-semibold text-secondary-dark-grey-500">
            {t('wiz.Completed_games')}
          </h5>
          {isLoading ? (
            <Loader />
          ) : (
            data?.results.map((item) => (
              <PvPCompletedCard
                key={item.id}
                prizeAmount={item.prize_amount}
                createdAt={item.created_at}
                initiator={item.initiator}
                opponent={item.opponent}
                participants={item.participants}
                winner={item.winner}
              />
            ))
          )}
        </div>
      </div>
      <WizRulesModal isOpen={isRulesOpen} onClose={handleRules} />
      <WizBidModal
        isOpen={isBidOpen}
        onClose={handleBid}
        onRules={() => {
          handleBid();
          handleRules();
        }}
      />
    </>
  );
};
