import instance, { AUTH_API_URL, WIZ_API_URL } from '../instance';
import axios from 'axios';
import { User } from '../../models';
import { WizStatusEnum } from '../../models/Wiz';
import { ResponsePagination } from '../../models/Response';

export const WIZ_TOKEN_STORAGE_KEY = 'wiz-anonymous-token';

export enum WizLevelEnum {
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard',
}

export enum WizDailyStatusEnum {
  UPCOMING = 'upcoming',
  ACTIVE = 'active',
  EXPIRED = 'expired',
}

export type Answer = {
  id: number;
  text: string;
  is_correct_answer: boolean;
};

export type Category = {
  category_name: string;
  title: string;
  id: number;
  image: string;
};

export type Question = {
  text: string;
  id: number;
  question_number: number;
  answers: Answer[];
  category: Category;
  passed: boolean;
};

export type DayResult = {
  day_of_week: number;
  user_answers: Answer[];
};

export type LeaderboardUser = {
  total_reward_amount: number;
  user: User;
};

export type TCA = {
  ciphertext: number[];
  iv: number[];
};

export type TStart = {
  ca: TCA;
  questions: Question[];
  start_quiz: TStartQuiz;
};

export type TResult = {
  ea: TCA;
  correct: number;
};

export type TStartQuiz = {
  id: number;
  category: number;
  last_attempt_time: string;
  user_guid: string;
};

export type TAward = { success: boolean; amount: string };

export type TAnswers = {
  id: number;
  text: string;
};

export type TQuestion = {
  id: number;
  text: string;
  answers: TAnswers[];
};

export type TCommonQuestion = {
  id: number;
  current_question: number;
  total_questions: number;
  question: TQuestion;
};

export type TUserMetric = {
  user_guid: string;
  lives: number;
  consecutive_days: number;
  next_life_recovery_time: number;
};

export type TDailyMultiplierInfo = {
  day: number;
  multiplier: number;
  status: WizDailyStatusEnum;
};

export type TAnswerResult = {
  id: number;
  is_correct_answer: boolean;
  end_date_time: string;
  is_time_limited: boolean;
  correct_answers: number[];
};

export type TTestResult = {
  percentage_correct: number;
  earned_token: number;
  multiplier_token: number;
  total_token: number;
};

export type TPvPBids = Array<
  'pvp_quiz_low' | 'pvp_quiz_medium' | 'pvp_quiz_high' | 'pvp_quiz_max'
>;

export type TStartPvPQuiz = {
  id: number;
  initiator: string;
  opponent: string | null;
  status: string;
  bets: string[];
  chosen_bet: string | null;
  questions_data: TQuestionsData;
  created_at: string;
  started_at: string;
};

export type TParticipant = {
  guid: string;
  username: string;
  profile_image: string | null;
};

export type TPvPQuizInfo = {
  id: number;
  initiator: string;
  opponent: string;
  status: string;
  chosen_bet: string;
  created_at: string;
  started_at: string;
  prize_amount: string;
  initiator_correct_answers: number;
  questions_data: TQuestionsData[];
  questions_encrypt: TQuestionsEncrypt;
  opponent_correct_answers: number;
  initiator_completed_at: string;
  opponent_completed_at: string;
  winner: string;
  participants: TParticipant[];
};

type TPvPQuizHistoryResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    id: number;
    initiator: string;
    opponent: string;
    status: string;
    chosen_bet: string;
    created_at: string;
    initiator_correct_answers: number | null;
    opponent_correct_answers: number;
    initiator_completed_at: string | null;
    opponent_completed_at: string;
    prize_amount: number | null;
    winner: string | null;
    participants: TParticipant[];
  }[];
};

export type TQuestionsData = {
  id: number;
  text: string;
  category: Category;
  answers: Answer[];
};

type TQuestionsEncrypt = {
  iv: number[];
  ciphertext: number[];
};

export type TSubmitPvpQuizRequest = {
  iv: number[];
  ciphertext: number[];
};

const getHeaderWithToken = () => {
  const wizToken = localStorage.getItem(WIZ_TOKEN_STORAGE_KEY);
  return {
    headers: { Authorization: `Token ${wizToken}` },
  };
};

export function registerInWiz(jwtToken: string | null): Promise<string> {
  return axios
    .post(`${WIZ_API_URL}/register/`, {
      user_jwt_token: jwtToken,
    })
    .then((response) => response.data.key)
    .catch((error) => {
      throw error;
    });
}

export function startTest(): Promise<{
  key: string;
}> {
  return axios
    .post(`${WIZ_API_URL}/start-test/`, undefined, getHeaderWithToken())
    .then((response) => response.data.key)
    .catch((error) => {
      throw error;
    });
}

export function getStatus(): Promise<WizStatusEnum> {
  return axios
    .get(`${WIZ_API_URL}/wiz-status/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getQuestion(): Promise<null> {
  return axios
    .get(`${WIZ_API_URL}/get-question/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function submitAnswer(answerId?: number): Promise<null> {
  return axios
    .post(
      `${WIZ_API_URL}/submit-answer/`,
      answerId && { answer_id: answerId },
      getHeaderWithToken()
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function showResults(): Promise<DayResult[]> {
  return axios
    .get(`${WIZ_API_URL}/show-results/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMyIqa(): Promise<number> {
  return axios
    .get(`${WIZ_API_URL}/get-my-iqa/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function claimReward(jwtToken: string): Promise<{ reward_amount: number }> {
  return axios
    .post(
      `${WIZ_API_URL}/claim-reward/`,
      { user_jwt_token: jwtToken },
      getHeaderWithToken()
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getIsRewardClaimed(): Promise<boolean> {
  return axios
    .get(`${WIZ_API_URL}/is-reward-claimed/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getLeaderboardData(): Promise<LeaderboardUser[]> {
  return axios
    .get(`${WIZ_API_URL}/leaderboard/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function uploadResultToShare(form: FormData): Promise<{
  image: string;
}> {
  return axios
    .post(`${WIZ_API_URL}/upload-result-image/`, form, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

// NEW
export function getQuestionCategories(
  language: string,
  page?: number
): Promise<ResponsePagination<Category>> {
  return axios
    .get(`${WIZ_API_URL}/public/question-categories/`, {
      headers: {
        'Accept-Language': language,
      },
      params: { language, page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setWizStart(category?: number): Promise<TStart> {
  return instance()
    .post(`${WIZ_API_URL}/private/start/`, { category })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUserMetrics(): Promise<TUserMetric> {
  return instance()
    .get(`${WIZ_API_URL}/private/user-metrics/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getDailyMultiplierInfo(): Promise<TDailyMultiplierInfo[]> {
  return instance()
    .get(`${AUTH_API_URL}/private/daily-reward/wiz-reward/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getTokenMultiplicationWeek(): Promise<{ [key: number]: string }[]> {
  return instance()
    .get(`${WIZ_API_URL}/unauthorized/private/get-token-multiplication-week/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getReceivedTokens(): Promise<TUserMetric> {
  return instance()
    .get(`${WIZ_API_URL}/authorized/private/get-received-tokens/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCommonQuestion(
  started_quiz_id: number
): Promise<TCommonQuestion> {
  return instance()
    .post(
      `${WIZ_API_URL}/private/get-question/`,
      { started_quiz_id },
      { headers: {} }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setForceCompleteQuiz(started_quiz_id: number) {
  return instance()
    .post(`${WIZ_API_URL}/private/force-complete-quiz/${started_quiz_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCurrentStartedQuiz(): Promise<Category> {
  return instance()
    .get(`${WIZ_API_URL}/private/get-current-started-quiz/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setSubmitAnswer(
  quiz_question_id: number,
  question_answer_id: number,
  ca: { iv: number[]; ciphertext: number[] }
): Promise<TResult> {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      q_id: quiz_question_id,
      a_id: question_answer_id,
      ca,
    }),
  };

  return fetch(`${WIZ_API_URL}/check-answer`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data: TResult) => data)
    .catch((error) => {
      throw error;
    });
}

export function submitBulkAnswers(answers?: TCA[]): Promise<TTestResult> {
  return instance()
    .post(`${WIZ_API_URL}/private/submit/bulk-answers/`, answers)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPurchaseLife(): Promise<{ success: boolean }> {
  return instance()
    .post(`${WIZ_API_URL}/private/purchase-life/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setAuthorizedTokenAwards(started_quiz_id: number): Promise<TAward> {
  return instance()
    .post(`${WIZ_API_URL}/authorized/private/token-awards/${started_quiz_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getInfoEarnedTokens(
  started_quiz_id: number,
  answers?: TCA[]
): Promise<TTestResult> {
  return instance()
    .post(`${WIZ_API_URL}/private/info-earned-tokens/${started_quiz_id}/`, answers)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setStartPvPQuiz(bids: TPvPBids): Promise<TStartPvPQuiz> {
  return instance()
    .post(`${WIZ_API_URL}/private/start-pvp-quiz/`, { bids })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPvPQuizConnect(pvpQuizId: number): Promise<TPvPQuizInfo> {
  return instance()
    .get(`${WIZ_API_URL}/private/pvp-quiz/${pvpQuizId}/check-connect/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPvPQuizInfo(pvpQuizId: number): Promise<TPvPQuizInfo> {
  return instance()
    .get(`${WIZ_API_URL}/private/pvp-quiz/${pvpQuizId}/info/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function submitPvpQuiz(
  answers: TSubmitPvpQuizRequest[],
  pvpQuizId: number
): Promise<any> {
  return instance()
    .post(`${WIZ_API_URL}/private/submit-pvp-quiz/${pvpQuizId}/`, answers)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPvPQuizHistory(
  page: number = 1
): Promise<TPvPQuizHistoryResponse> {
  return instance()
    .get(`${WIZ_API_URL}/private/pvp-quiz-history/?page=${page}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
export function setCancelPvPQuiz(pvp_quiz_id: number): Promise<void> {
  return instance()
    .post(`${WIZ_API_URL}/private/cancel-pvp-quiz/${pvp_quiz_id}/`)
    .then(() => {})
    .catch((error) => {
      throw error;
    });
}
