import { useTranslation } from 'react-i18next';
import { Modal } from '../../horizon-components/Modal';
import { BottomSheet } from '../../horizon-components/bottom-sheet/ui';
import { MButton } from '../../horizon-components/MButton';
import { ReactComponent as QuestionIcon } from '../../assets/icons/wiz/question.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import { CoinIcon } from '../../components/CoinIcon';
import { Checkbox } from '../../horizon-components/Checkbox/Checkbox';
import classNames from 'classnames';
import { formatNumber } from '../../shared/utils/formatNumber';
import { useUserContext } from '../../providers';
import { useEffect, useMemo, useState } from 'react';
import { useRewardContext } from '../../providers/RewardProvider';
import {
  getPvPQuizInfoAsync,
  setGameState,
  startPvPQuizAsync,
} from '../../stores/wiz';
import { TPvPBids } from '../../api/wiz/wiz';
import { useAppDispatch } from '../../stores/hooks';
import { setUserSomeDataStorage } from '../../api';
import { setUserBids } from '../../stores/wiz';
import { MatchViewStatus } from '../../modules/Wiz/wiz-main/types';
import { sendPvpWizStart } from '../../hooks/useAnalytics';

interface WizBidModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  onRules?: () => void;
}

export const WizBidModal = ({ isOpen, onClose, onRules }: WizBidModalProps) => {
  const { t, i18n } = useTranslation(['translation']);
  const { wallet, updateWallet } = useUserContext();
  const [isPlayDisabled, setIsPlayDisabled] = useState(false);
  const { rewards } = useRewardContext();
  const { user } = useUserContext();
  const dispatch = useAppDispatch();
  const getBidsFromRewards = () => {
    const quizTypes = [
      'pvp_quiz_low',
      'pvp_quiz_medium',
      'pvp_quiz_high',
      'pvp_quiz_max',
    ] as const;
    return (
      rewards
        ?.filter((reward) =>
          quizTypes.includes(reward.tokenomic_type as TPvPBids[number])
        )
        .map((reward) => ({
          type: reward.tokenomic_type,
          price: Number(reward.value),
        })) || []
    );
  };

  const bids = useMemo(() => getBidsFromRewards(), [rewards]);

  const [selectedBids, setSelectedBids] = useState<
    { type: string; price: number }[]
  >([]);

  useEffect(() => {
    if (bids && wallet) {
      setSelectedBids(bids.filter((bid) => Number(wallet.value) >= bid.price));
    }
  }, [bids, wallet]);

  const toggleBid = (type: string, price: number) => {
    if (Number(wallet?.value) >= price) {
      setSelectedBids((prev) =>
        prev.some((bid) => bid.price === price)
          ? prev.filter((bid) => bid.price !== price)
          : [...prev, { type, price }]
      );
    }
  };

  const onPlay = async () => {
    setIsPlayDisabled(true);
    sendPvpWizStart();
    const selectedTypes = selectedBids.map((bid) => bid.type);
    const selectedBidsWithPrices = selectedBids.map((bid) => ({
      type: bid.type,
      price: bid.price,
    }));
    try {
      dispatch(setUserBids(selectedBidsWithPrices));

      const result = await dispatch(
        startPvPQuizAsync(selectedTypes as TPvPBids)
      ).unwrap();
      dispatch(setUserBids(selectedBidsWithPrices));
      const matchViewStatus: MatchViewStatus = {
        matchId: result.id.toString(),
        startedAt: result.started_at,
        hasSubmittedAnswers: false,
        isViewedResult: false,
      };
      setUserSomeDataStorage('matchViewStatus', matchViewStatus, user?.guid);
      if (result.opponent === null) {
        dispatch(setGameState('wait'));
      } else if (result.opponent) {
        await dispatch(getPvPQuizInfoAsync(result.id)).unwrap();
        dispatch(setGameState('start'));
      }
      onClose?.();
    } catch (error) {
      console.error('Failed to start PvP quiz:', error);
    }
  };

  const renderContent = () => (
    <div className="flex w-full flex-col gap-5">
      <h4 className="text-center text-lg font-bold text-secondary-grey-900 lg:hidden lg:text-left">
        {t('wiz.Select_bids')}
      </h4>
      <p className="text-sm font-medium text-secondary-grey-900">
        {t('wiz.bid_content')}
      </p>
      <div className="grid grid-cols-2 gap-2">
        {bids.map((bid, idx) => {
          const canAfford = Number(wallet?.value) >= bid.price;
          return (
            <div
              className={classNames(
                'flex cursor-pointer items-center justify-between rounded-2xl border p-4',
                {
                  'border-primary-purple-blue-200 bg-primary-purple-blue-100':
                    selectedBids.some((selected) => selected.price === bid.price) &&
                    canAfford,
                  'border-gray-200 bg-white':
                    !selectedBids.some((selected) => selected.price === bid.price) &&
                    canAfford,
                  'cursor-not-allowed !border-secondary-grey-500 !bg-secondary-grey-300':
                    !canAfford,
                }
              )}
              key={idx}
              onClick={() => toggleBid(bid.type, bid.price)}
            >
              <div
                className={classNames(
                  'flex items-center gap-0.5 text-base font-semibold',
                  {
                    'text-primary-purple-blue-700': canAfford,
                    '!text-secondary-grey-600': !canAfford,
                  }
                )}
              >
                {formatNumber(bid.price.toString() || '0', i18n.language)}{' '}
                <CoinIcon
                  className={classNames('h-4', {
                    'fill-primary-purple-blue-700': canAfford,
                    'fill-secondary-grey-600': !canAfford,
                  })}
                />
              </div>
              <Checkbox
                checked={selectedBids.some(
                  (selected) => selected.price === bid.price
                )}
                onChange={() => {}}
                color={'blue'}
                extra={classNames({
                  '!rounded-full !bg-primary-purple-blue-400 !border !border-primary-purple-blue-500':
                    selectedBids.some((selected) => selected.price === bid.price) &&
                    canAfford,
                  '!rounded-full !bg-white !border !border-gray-500':
                    !selectedBids.some((selected) => selected.price === bid.price) &&
                    canAfford,
                  '!rounded-full !bg-secondary-grey-300 !border !border-secondary-grey-500':
                    !canAfford,
                })}
              />
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-2 gap-2">
        <MButton
          variant="secondary"
          color="primary"
          size="xs"
          className="!rounded-full"
          onClick={onRules}
        >
          {t('wiz.Rules')}
          <QuestionIcon />
        </MButton>
        <MButton
          variant="highlighted"
          color="primary"
          size="xs"
          className="!rounded-full"
          onClick={onPlay}
          disabled={selectedBids.length === 0 || isPlayDisabled}
        >
          {t('buttons.Play')}
          <PlayIcon />
        </MButton>
      </div>
    </div>
  );

  return window.innerWidth > 768 ? (
    <Modal isOpen={isOpen} onClose={onClose} title={t('wiz.Select_bids')}>
      {renderContent()}
    </Modal>
  ) : (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      {renderContent()}
    </BottomSheet>
  );
};
