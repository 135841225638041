import ThinkingImg from '../../../assets/images/wiz/thinking.webp';
import FaceWithMonocleImg from '../../../assets/images/wiz/face-with-monocle.webp';
import ColdFaceImg from '../../../assets/images/wiz/cold-face.webp';
import DisguisedFaceImg from '../../../assets/images/wiz/disguised-face.webp';
import ZanyFaceImg from '../../../assets/images/wiz/zany-face.webp';
import GrinningImg from '../../../assets/images/wiz/grinning.webp';
import WinkingFaceWithTongueImg from '../../../assets/images/wiz/winking-face-with-tongue-emoji.webp';
import SmilingFaceWizhSunglassesImg from '../../../assets/images/wiz/smiling-face-with-sunglasses.webp';
import FaceWithPeekingEyeImg from '../../../assets/images/wiz/face-with-peeking-eye.webp';
import HuggingFaceImg from '../../../assets/images/wiz/hugging-face.webp';
import CryingImg from '../../../assets/images/wiz/crying.webp';
import PleadingFaceImg from '../../../assets/images/wiz/pleading-face-emoji.webp';
import CryingFaceImg from '../../../assets/images/wiz/crying-face.webp';
import WearyFaceImg from '../../../assets/images/wiz/weary-face.webp';
import FaceWithSteamFromNoseImg from '../../../assets/images/wiz/face-with-steam-from-nose.webp';
import SavoringImg from '../../../assets/images/wiz/savoring.webp';
import DizzyFaceImg from '../../../assets/images/wiz/dizzy-face-emoji.webp';
import FaceWithOpenMouthImg from '../../../assets/images/wiz/face-with-open-mouth.webp';
import FaceWithRaisedEyebrowImg from '../../../assets/images/wiz/face-with-raised-eyebrow.webp';
import AstonishedFaceImg from '../../../assets/images/wiz/astonished-face.webp';

type GameState = 'wait' | 'win' | 'draw' | 'lost';

const emojiMap = {
  wait: [
    ThinkingImg,
    FaceWithMonocleImg,
    ColdFaceImg,
    DisguisedFaceImg,
    ZanyFaceImg,
  ],
  win: [
    GrinningImg,
    WinkingFaceWithTongueImg,
    SmilingFaceWizhSunglassesImg,
    FaceWithPeekingEyeImg,
    HuggingFaceImg,
  ],
  lost: [
    CryingImg,
    PleadingFaceImg,
    CryingFaceImg,
    WearyFaceImg,
    FaceWithSteamFromNoseImg,
  ],
  draw: [
    SavoringImg,
    DizzyFaceImg,
    FaceWithOpenMouthImg,
    FaceWithRaisedEyebrowImg,
    AstonishedFaceImg,
  ],
};

export const useRandomEmoji = () => {
  const getRandomEmoji = (state: GameState) => {
    const emojis = emojiMap[state];
    return emojis[Math.floor(Math.random() * emojis.length) % emojis.length];
  };

  return { getRandomEmoji };
};
