import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getWizCompletedCount } from '../../../../api/bid';
import {
  getPrivateFavouriteCoursesV3,
  getPublicCoursesV3,
  subjectMetricV3,
} from '../../../../api/courseV3';
import { getCourseProgressV3 } from '../../../../api/digitalPassV3';
import { Loader } from '../../../../components/Loader';
import { NotFound } from '../../../../components/NotFound/NotFound';
import { useMyCourses } from '../../../../entities/Courses';
import { PlatformsEnum } from '../../../../entities/Platforms/platforms';
import { Logout } from '../../../../features/Logout';
import { ProfileCardNew } from '../../../../features/profile-card-new';
import { ProfileReferal } from '../../../../features/ProfileReferal';
import useFetch from '../../../../hooks/useFetch';
import { useNavigate } from '../../../../hooks/useNavigate';
import { Link } from '../../../../horizon-components/Link';
import { MButton } from '../../../../horizon-components/MButton';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { CourseCardItem, CourseStatus } from '../../../../models/Course';
import { ResponsePagination } from '../../../../models/Response';
import { useUserContext } from '../../../../providers';
import getPlatform from '../../../../utils/getPlatform';
import { AsideMenu } from '../../../../widgets/AsideMenu';
import { CourseCard } from '../../../../widgets/CourseCard';
import { ProfileCreateCourse } from '../../../../widgets/ProfileCreateCourse';
import { DeletedProfile } from './DeletedProfile';
import { MyProfileHeader } from './my-profile-header';
import './my-profile.scss';
import { ProfileCoursesStatsCard } from './profile-courses-stats-card';
import { ProfileStats } from './profile-stats';
import { ProfileSubjectsStatsCard } from './profile-subjects-stats-card';
import { isMobile } from '../../../../shared/utils/isMobile';

export const MyProfile: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { id: profileId } = useParams();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [selected, setSelected] = useState<string>('info');
  const {
    data: verifiedCourses,
    isLoading: isVerifiedLoading,
    fetchData: fetchVerifiedData,
  } = useMyCourses();
  const [favouriteCourses, setFavouriteCourses] =
    useState<ResponsePagination<CourseCardItem> | null>(null);
  const platform = getPlatform();
  const [isFavouritesLoading, setIsFavouritesLoading] = useState(false);
  const { data: metric, fetchData: fetchMetric } = useFetch(subjectMetricV3);
  const { data: courses, fetchData: fetchCoursesProgress } =
    useFetch(getCourseProgressV3);
  const { data: wiz_completed, fetchData: fetchWizCompleted } =
    useFetch(getWizCompletedCount);

  const mobile = isMobile();

  useEffect(() => {
    profileId && fetchCoursesProgress(profileId);
    profileId && fetchWizCompleted(profileId);
    profileId && fetchMetric(profileId);
  }, [profileId, t]);

  const fetchFavouriteCourses = async (page: number) => {
    if (favouriteCourses) {
      return;
    }
    setIsFavouritesLoading(true);
    try {
      const favouriteIds = await getPrivateFavouriteCoursesV3();
      if (favouriteIds.length > 0) {
        const favouriteCourses_ = await getPublicCoursesV3({
          id__in: favouriteIds.join(','),
          page,
        });
        setFavouriteCourses(favouriteCourses_);
      }
    } finally {
      setIsFavouritesLoading(false);
    }
  };

  const asideMenu = useMemo(
    () => [
      { id: 'courses', title: t('menu.Courses'), isShow: true },
      {
        id: 'favorites',
        title: t('feed.favorites'),
        isShow: user?.guid === profileId,
      },
    ],
    [t, user?.guid, profileId]
  );
  const mobileMenu = useMemo(
    () => [
      { id: 'info', title: t('course.Information'), isShow: true },
      { id: 'courses', title: t('menu.Courses'), isShow: true },
      {
        id: 'favorites',
        title: t('feed.favorites'),
        isShow: user?.guid === profileId,
      },
    ],
    [t, user?.guid, profileId]
  );
  useEffect(() => {
    selected === 'courses' && fetchVerifiedData(CourseStatus.Verified);
    selected === 'favorites' && fetchFavouriteCourses(1);
  }, [selected, t, profileId]);

  if (!user) {
    navigate(MainRoutes.auth + AuthRoutes.signin);
  }
  if (profileId === 'undefined') {
    return <DeletedProfile />;
  }

  return (
    <div className="MyProfile">
      <MyProfileHeader className="hidden lg:flex" />
      <div className={`MyProfile__content`}>
        <ProfileCardNew
          className="lg:w-2/5"
          gpa_avg={metric?.gpa_avg}
          completed={wiz_completed?.quiz_count}
        />
        <ProfileStats
          className="hidden lg:flex lg:w-2/6"
          metric={metric}
          courses={courses?.results}
        />
        <ProfileReferal className="!hidden lg:!flex lg:!w-2/6" />
      </div>
      <div className="MyProfile__nav">
        {mobileMenu
          .filter((menu) => menu.isShow)
          .map((menu) => (
            <MButton
              onClick={() => setSelected(menu.id)}
              key={menu.id}
              variant={selected === menu.id ? 'highlighted' : 'secondary'}
              color="tab"
              size="xs"
            >
              {menu.title}
            </MButton>
          ))}
      </div>
      <div className={`MyProfile__content !items-start lg:grid-cols-2`}>
        {selected === 'info' && (
          <div className="flex flex-col gap-2 lg:hidden">
            <ProfileSubjectsStatsCard metric={metric} />
            <ProfileCoursesStatsCard courses={courses} />
            <ProfileReferal />
            {platform !== PlatformsEnum.TELEGRAM && (
              <div className="MyProfile__card">
                <Logout />
              </div>
            )}
          </div>
        )}
        <div className="hidden flex-col gap-5 lg:flex">
          <AsideMenu
            menu={asideMenu.filter((menu) => menu.isShow)}
            onClick={setSelected}
            active={selected}
          />
          <ProfileCreateCourse />
        </div>
        <div className="w-full lg:col-span-2">
          {isVerifiedLoading || isFavouritesLoading ? (
            <Loader />
          ) : selected === 'courses' ? (
            !!verifiedCourses?.length ? (
              <div className={'flex w-full flex-wrap gap-2 lg:gap-5'}>
                <div
                  className={
                    'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:grid-cols-3 4xl:grid-cols-4'
                  }
                >
                  {verifiedCourses
                    ?.slice(0, mobile ? 7 : 6)
                    .map((course, i) => (
                      <CourseCard
                        course={course as unknown as CourseCardItem}
                        key={course.id}
                      />
                    ))}
                </div>
              </div>
            ) : (
              <div>
                <NotFound text={' '} />
                <Link to={MainRoutes.edu + EduRoutes.courses}>
                  <MButton
                    variant="highlighted"
                    color="primary"
                    className="m-auto w-full lg:w-auto"
                    size="lg"
                  >
                    {t('buttons.Find_courses')}
                  </MButton>
                </Link>
              </div>
            )
          ) : null}

          {selected === 'favorites' ? (
            <>
              {!!favouriteCourses?.results?.length && (
                <div className={'flex w-full flex-wrap gap-2 lg:gap-5'}>
                  <div
                    className={
                      'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:grid-cols-3 4xl:grid-cols-4'
                    }
                  >
                    {favouriteCourses?.results?.map((course, i) => (
                      <CourseCard
                        course={course as unknown as CourseCardItem}
                        key={course.id}
                        favorite={true}
                      />
                    ))}
                  </div>
                </div>
              )}
              {!favouriteCourses?.results?.length && !isFavouritesLoading && (
                <div>
                  <NotFound text={' '} />
                  <Link to={MainRoutes.edu + EduRoutes.courses}>
                    <MButton
                      variant="highlighted"
                      color="primary"
                      className="m-auto w-full lg:w-auto"
                      size="lg"
                    >
                      {t('buttons.Find_courses')}
                    </MButton>
                  </Link>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
