import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../stores/hooks';
import { submitPvpQuiz, TCA } from '../../../api/wiz/wiz';
import { getUserSomeDataStorage, setUserSomeDataStorage } from '../../../api';
import { MatchViewStatus } from '../wiz-main/types';
import { setGameState } from '../../../stores/wiz';
import {
  MainRoutes,
  WizRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import useFetch from '../../../hooks/useFetch';

interface UseExitConfirmationProps {
  encryptedAnswers: TCA[];
  pvpQuizInfo: any;
  currentQuestionIndex: number;
  user: any;
}

export const useExitConfirmation = ({
  encryptedAnswers,
  pvpQuizInfo,
  currentQuestionIndex,
  user,
}: UseExitConfirmationProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { fetchData: fetchSubmitPvpQuiz } = useFetch(submitPvpQuiz);

  const handleCloseClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleConfirmExit = () => {
    const storageMatchViewStatus = getUserSomeDataStorage(
      'matchViewStatus',
      user?.guid
    ).matchId;
    const matchViewStatus: MatchViewStatus = {
      matchId: pvpQuizInfo?.id.toString() ?? storageMatchViewStatus.matchId,
      startedAt: pvpQuizInfo?.started_at ?? storageMatchViewStatus.startedAt,
      hasSubmittedAnswers: true,
      isViewedResult: false,
      questions_data:
        pvpQuizInfo?.questions_data ?? storageMatchViewStatus.questions_data,
      answeredQuestions: currentQuestionIndex,
    };
    fetchSubmitPvpQuiz(encryptedAnswers, Number(matchViewStatus.matchId))
      .then(() => {
        setUserSomeDataStorage('matchViewStatus', matchViewStatus, user?.guid);
        dispatch(setGameState('null'));
        navigate(`${MainRoutes.wiz}${WizRoutes.quizzes}`);
      })
      .catch((error) => {
        console.error('Error submitting quiz answers:', error);
        setUserSomeDataStorage('matchViewStatus', matchViewStatus, user?.guid);
        dispatch(setGameState('null'));
        navigate(`${MainRoutes.wiz}${WizRoutes.quizzes}`);
      });
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    handleCloseClick,
    handleModalClose,
    handleConfirmExit,
  };
};
