import { DeleteAccount } from '../../../../features/DeleteAccount';
import { Logout } from '../../../../features/Logout';
import { ProfileAccountSettings } from '../../../../features/ProfileAccountSettings';
import { ProfileChangePassword } from '../../../../features/ProfileChangePassword';
import { Card } from '../../../../horizon-components/Card/Card';
import { useUserContext } from '../../../../providers';
import { InfoHeader } from './InfoHeader';
import './InfoUser.scss';

export const InfoUser: React.FC = () => {
  const { user } = useUserContext();
  const isTelegramUser = user?.email?.endsWith('@tguser.com');
  return (
    <div className="InfoUser w-full">
      <InfoHeader />
      <div className={'InfoUser__content m-auto max-w-6xl'}>
        <div className={'InfoUser__col lg:col-span-1'}>
          <Card extra={'InfoUser__card'}>
            <ProfileAccountSettings isTelegramUser={isTelegramUser} />
          </Card>
        </div>
        <div className={'InfoUser__col lg:col-span-1'}>
          {!isTelegramUser && (
            <Card extra={'InfoUser__card'}>
              <ProfileChangePassword />
            </Card>
          )}
          <Card extra={'InfoUser__card'}>
            <Logout />
          </Card>
          {!isTelegramUser && (
            <Card extra={'InfoUser__card'}>
              <DeleteAccount />
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};
